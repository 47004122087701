import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import Header from '@/src/components/Header';
import axios from 'axios';
// import Plan from './Plan';
import { Typography } from '@mui/material';
import Main from '@/src/components/Main';
import SocialProof from './SocialProof';
import PricingFAQs from './PricingFAQ';
import Footer from '@/src/components/Footer';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
	max-width: 900px;
	margin: 0 auto;
	padding-top: 1rem;
	min-height: 100vh;
`;

const PlansContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
	@media (max-width: 900px) {
		grid-template-columns: 1fr;
		max-width: 350px;
	}
  gap: 1rem;
	width: 100%;
	margin-top: 2rem;
	margin-bottom: 4rem;
`;


const Pricing = () => {
	const [plans, setPlans] = React.useState([]);
	const [loading, setLoading] = React.useState(true);

	useEffect(() => {
		axios.get('/api/plans').then((res) => {
			setPlans(res.data);
			setLoading(false);
		});
	}, []);

	console.log(plans);

	return (
		<div>
			<Header />
			<Main>
				<ContentContainer>
					<Typography fontSize={48} lineHeight={1.2} variant='h1'>Pricing</Typography>
					<Typography variant='body1' color='grey.700' sx={{ textAlign: 'center', marginTop: '.5rem' }}>Join other customer obsessed companies</Typography>
					<PlansContainer>
						{/* {plans.map((plan) => {
							if (plan.name === 'Free') return;
							return (
								<Plan key={plan.name} plan={plan} />
							);
						}
						)}
						{loading && (
							<>
								<Plan loading />
								<Plan loading />
								<Plan loading />
							</>
						)} */}
					</PlansContainer>
					<SocialProof />
					<br />
					<br />
					<br />
					<br />
					<PricingFAQs />
				</ContentContainer>
			</Main>
			<Footer />
		</div>
	);
};

export default Pricing;
