import { Typography } from '@mui/material';
import React from 'react';
import ycImg from '@/public/images/yc.png';
import onDeckImg from '@/public/images/ondeck.png';
import styled from '@emotion/styled';

const LogoContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const SocialProof = () => {
	return (
		<Container>
			<Typography variant="h4">Trusted by companies from</Typography>
			<LogoContainer>
				<img src={ycImg} alt="Y Combinator" height={82} />
				{/* <img src={onDeckImg} alt="On Deck" height={42} /> */}
			</LogoContainer>
		</Container>
	);
};

export default SocialProof;
