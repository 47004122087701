import React from 'react';
import classNames from 'classnames';
import { Editor, useCurrentEditor } from '@tiptap/react';
import { useInView } from 'react-cool-inview';
import {
	RiBold,
	RiItalic,
	RiStrikethrough,
	RiCodeSSlashLine,
	RiH1,
	RiH2,
	RiH3,
	RiH4,
	RiH5,
	RiH6,
	RiParagraph,
	RiListOrdered,
	RiListUnordered,
	RiCodeBoxLine,
	RiLink,
	RiLinkUnlink,
	RiDoubleQuotesL,
	RiSeparator,
	RiTextWrap,
	RiFormatClear,
	RiArrowGoBackLine,
	RiArrowGoForwardLine,
} from '@remixicon/react';


import { setLink } from './set-link';
import styled from '@emotion/styled';
import { colors } from '@mui/joy';

const Container = styled.div<{ sticky: boolean }>`
	background: white;
	border-bottom: 1px solid ${colors.grey[200]};
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	position: sticky;
	top: 0;
	z-index: 1;

	&.sticky {
			border-top: 2px solid transparent;
			box-shadow: 0px 3px 5px -3px rgba(#333333, 0.5);
	}
`;

const Bar = styled.div`
	display: flex;
`;

const Divider = styled.div`
	border: none;
	border-left: 1px solid ${colors.grey[200]};
	margin: 2px 10px;
`;

const Icon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 32px;
	height: 32px;
	border-radius: 4px;
	color: ${colors.grey[600]};

	&:hover {
		background: ${colors.grey[200]};
	}


`;

function Toolbar() {
	const { editor } = useCurrentEditor();
	const isCursorOverLink = editor.getAttributes('link').href;

	const { observe, inView } = useInView({
		rootMargin: '-1px 0px 0px 0px',
		threshold: [1],
	});

	return (
		<Container ref={observe} sticky={!inView}>
			<Bar className='Toolbar'>
				<Icon onClick={() => editor.chain().focus().toggleBold().run()}>
					<RiBold />
				</Icon>
				<Icon onClick={() => editor.chain().focus().toggleItalic().run()}>
					<RiItalic />
				</Icon>
				<Icon onClick={() => editor.chain().focus().toggleStrike().run()}>
					<RiStrikethrough />
				</Icon>
				<Icon onClick={() => editor.chain().focus().toggleCode().run()}>
					<RiCodeSSlashLine />
				</Icon>
				<Divider />
				<Icon
					className='icon'
					onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}>
					<RiH1 />
				</Icon>
				<Icon
					className='icon'
					onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}>
					<RiH2 />
				</Icon>
				<Icon
					className='icon'
					onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}>
					<RiH3 />
				</Icon>
				<Icon
					className='icon'
					onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}>
					<RiH4 />
				</Icon>
				<Icon
					className='icon'
					onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}>
					<RiH5 />
				</Icon>
				<Icon
					className='icon'
					onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}>
					<RiH6 />
				</Icon>
				<Icon onClick={() => editor.chain().focus().setParagraph().run()}>
					<RiParagraph />
				</Icon>
				<Icon
					className='icon'
					onClick={() => editor.chain().focus().toggleBulletList().run()}>
					<RiListOrdered />
				</Icon>
				<Icon
					className='icon'
					onClick={() => editor.chain().focus().toggleOrderedList().run()}>
					<RiListUnordered />
				</Icon>
				<Icon
					className='icon'
					onClick={() => editor.chain().focus().toggleCodeBlock().run()}>
					<RiCodeBoxLine />
				</Icon>
				<Divider />
				<Icon onClick={() => setLink(editor)}>
					<RiLink />
				</Icon>
				<Icon
					className={classNames('icon', { disabled: !isCursorOverLink })}
					onClick={() => setLink(editor)}>
					<RiLinkUnlink />
				</Icon>
				<Divider />
				<Icon
					className='icon'
					onClick={() => editor.chain().focus().toggleBlockquote().run()}>
					<RiDoubleQuotesL />
				</Icon>
				<Icon
					className='icon'
					onClick={() => editor.chain().focus().setHorizontalRule().run()}>
					<RiSeparator />
				</Icon>
				<Divider />
				<Icon onClick={() => editor.chain().focus().setHardBreak().run()}>
					<RiTextWrap />
				</Icon>
				<Icon
					className='icon'
					onClick={() => editor.chain().focus().unsetAllMarks().clearNodes().run()}>
					<RiFormatClear />
				</Icon>
				<Divider />
				<Icon onClick={() => editor.chain().focus().undo().run()}>
					<RiArrowGoBackLine />
				</Icon>
				<Icon onClick={() => editor.chain().focus().redo().run()}>
					<RiArrowGoForwardLine />
				</Icon>
			</Bar>
		</Container>
	);
}

export { Toolbar };
