import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Chip, IconButton, Typography } from '@mui/joy';
import { ReactComponent as FeedFlowIcon } from '@/public/icon.svg';
import { UserContext } from '@/src/context/UserContext';
import { Paper } from '@mui/material';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as BugIcon } from '@/public/icons/bug.svg';
import { ReactComponent as SettingsIcon } from '@/public/icons/settings.svg';
import { AppConfigurationContext } from '@/src/context/AppContext';

const Container = styled(Box)`
  height: 100%;

`;


interface ConfigurationTreeProps {
}

const ConfigurationTree = (props: ConfigurationTreeProps) => {
	const configuration = useContext(AppConfigurationContext);

	return (
		<Container display='flex' justifyContent='center'>
			{/* tree */}
		</Container>
	);
};

export default ConfigurationTree;
