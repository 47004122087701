import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Chip, IconButton, Typography } from '@mui/joy';
import { ReactComponent as FeedFlowIcon } from '@/public/icon.svg';
import { UserContext } from '@/src/context/UserContext';
import { Paper } from '@mui/material';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as BugIcon } from '@/public/icons/bug.svg';
import { ReactComponent as SettingsIcon } from '@/public/icons/settings.svg';
import { AppConfigurationContext } from '@/src/context/AppContext';


const HeaderWrapper = styled(Paper)<{ noMarginBottom: boolean }>`
  display: flex;
  align-items: center;
  padding: .5rem;
  gap: 1rem;
  justify-content: space-between;
	background-color: white;
  margin: 0 auto;
  background: white;
  margin-top: 1rem;
  padding: 1rem;
  margin-bottom: ${props => props.noMarginBottom ? '0' : '1rem'};
	width: 100%;
	@media (max-width: 768px) {
	}
`;

const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	gap: .5rem;
`;

const AdminNavLink = styled(NavLink)`
	@media (max-width: 768px) {
		display: none;
	}
`;


const DashboardHeader = (props: { noMarginBottom?: boolean }) => {
	const configuration = useContext(AppConfigurationContext);
	const { user, loaded, setIsDebugMode, isDebugMode } = useContext(UserContext);
	const notLoggedIn = loaded && !user?.email;
	const pathName = useLocation().pathname;

	return (
		<HeaderWrapper noMarginBottom={props.noMarginBottom}>
			<Box display='flex' alignItems={'center'} gap={1}>
				<a href='/'>
					<TitleContainer>
						{/* <configuration.icon width={24} height={24} /> */}
						<img src={configuration.icon} alt='icon' height={24} />
						<Typography level='h4'>{configuration.title}</Typography>
					</TitleContainer>
				</a>
				{pathName === '/config' && (
					<Chip color='neutral'>Config</Chip>
				)}
			</Box>

			{configuration.title === 'FeedFlow' ? (
				<>
					{!notLoggedIn ? (
						<Box display='flex' alignItems='center'>
							{user?.isAdmin && (
								<AdminNavLink to='/admin'>
									<Button variant='plain' color='danger'>Admin</Button>
								</AdminNavLink>
							)}
							<NavLink to='/team'>
								<Button sx={{ marginRight: '1rem' }} variant='plain' color='neutral'>Team</Button>
							</NavLink>
							{pathName === '/' ? (
								<Link to='/setup'>
									<Button>Listeners</Button>
								</Link>
							) : (
								<Link to='/'>
									<Button>Feed</Button>
								</Link>
							)}
							{user?.isAdmin && (
								<IconButton sx={{ marginLeft: '1rem' }} variant={isDebugMode ? 'solid' : 'soft'} color='danger' size='md' onClick={() => setIsDebugMode(!isDebugMode)}>
									<BugIcon fill='currentColor' width={18} height={18} />
								</IconButton>
							)}
						</Box>
					) : (
						<a href='/login'>
							<Button>Sign up</Button>
						</a>
					)}
				</>
			) : (
				<Box display='flex' alignItems='center'>
					{configuration.subdomain === 'rfpcanada' && (
						<NavLink to='/persuit/123'>
							<Button sx={{ marginRight: '0.5rem' }} variant='plain'>Persuits</Button>
						</NavLink>
					)}
					{!notLoggedIn && (
						<NavLink to='/'>
							<Button color='primary'>Dashboard</Button>
						</NavLink>
					)}
					{configuration.isConfigurable && user?.isAdmin && (
						<NavLink to='/config'>
							<IconButton variant='soft' sx={{ marginLeft: '.5rem' }}>
								<SettingsIcon fill='currentColor' width={16} height={16} />
							</IconButton>
						</NavLink>
					)}
				</Box>
			)
			}
			{/* <a href="https://ta124tyg42e.typeform.com/to/SmevfpZR" target="_blank" rel="noreferrer">
				<ButtonSecondary>Join Waitlist</ButtonSecondary>
			</a> */}

		</HeaderWrapper>
	);
};

export default DashboardHeader;
