import { Typography, Chip, Box, colors } from '@mui/joy';
import moment from 'moment';
import React from 'react';
import { MidDot } from '../Dashboard/Media';
import { trimStringEllipses } from '@/src/util';
import styled from '@emotion/styled';

interface RFPMediaProps {
  rfp: any;
	searchTerms?: string[];
}

const TopContainer = styled(Box)`
	@media (max-width: 768px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;


const RFPMedia = (props: RFPMediaProps) => {
	const { rfp, searchTerms } = props;

	console.log(rfp);

	const closingDate = moment(rfp.closingDate).format('MMM D, YYYY');
	const openDate = moment(rfp.openDate).format('MMM D, YYYY');

	const href = 'https://canadabuys.canada.ca' + rfp.href;

	return (
		<div>
			<Box sx={{ position: 'relative', padding: '1rem', borderBottom: `1px solid ${colors.grey[200]}` }}>
				{/* <Box display='flex' gap={0.5}>
					<Chip size='sm'><b>Category</b> {rfp.category}</Chip>
					<Chip size='sm'><b>Organization</b> {rfp.organization}</Chip>
				</Box> */}
				<TopContainer display='flex' gap={.5} alignItems='center'>
					<Typography level='title-sm'>Due {closingDate}</Typography>
					<Chip size='sm'><b>Category</b> {rfp.category}</Chip>
					<Chip size='sm'><b>Organization</b> {rfp.organization}</Chip>
					{/* <Typography level='body-xs'>
						{'2 hours ago'}
					</Typography>
					<MidDot />
					<Box display='flex' alignItems='center' gap={0.25}>
						<CommentIcon fill={colors.grey[600]} width={12} height={12} />
						<Typography level='body-xs'>{ 4 }</Typography>
					</Box>
					<Box display='flex' alignItems='center' gap={0.25}>
						<UpArrowIcon fill={colors.grey[600]}  width={10} height={10} />
						<Typography level='body-xs'>{ 4 }</Typography>
					</Box> */}
				</TopContainer>
				<a href={href} target='_blank' rel='noreferrer'>
					<Typography level='title-md'><span dangerouslySetInnerHTML={{ __html: rfp.title }} /></Typography>
				</a>
				{/* {isDebugMode && (
        <Typography level='body-xs'>
          {props.media.listenerSource.listener.prompt}
        </Typography>
      )} */}
				<Typography level='body-sm'>
					<b>{openDate}</b> <MidDot/> <span dangerouslySetInnerHTML={{ __html: trimStringEllipses(rfp.description || 'no description', 400) }} />
				</Typography>
			</Box>
		</div>
	);
};

export default RFPMedia;
