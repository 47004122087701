import { Theme } from '@emotion/react';
import { extendTheme } from '@mui/joy';
import { createTheme } from '@mui/material';
import type { PaletteRange } from '@mui/joy/styles';

declare module '@mui/joy/styles' {
  interface ColorPalettePropOverrides {
    // apply to all Joy UI components that support `color` prop
    secondary: true;
  }

  interface Palette {
    // this will make the node `secondary` configurable in `extendTheme`
    // and add `secondary` to the theme's palette.
    secondary: PaletteRange;
  }
}

export const colors = {
	black: '#000000',
	yellow: {
		light: '#fff2ab',
		main: '#FFD700',
		dark: '#cbad02',
	}, blue: {
		light: '#aadcfc',
		main: '#87CEFA',
		dark: '#87CEFA',
	},
};

declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary'];
  }

  interface PaletteOptions {
    black?: PaletteOptions['primary'];
  }
}

export const muiTheme = createTheme({
	typography: {
		fontFamily: 'Geist, sans-serif',
	},
	components: {
		MuiPaper: {
			styleOverrides: {
				root: {
					padding: '1rem',
					boxShadow: 'none',
					border: '1px solid #E4E4E4',
					borderRadius: '8px',
				}
			}
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiSkeleton: {
			styleOverrides: {
				root: {
					borderRadius: '8px',
				}
			},
			defaultProps: {
				animation: 'wave',
			}
		},
	},
});


export const joyTheme = extendTheme({
	fontFamily: {
		body: 'Geist, sans-serif',
	},
	components: {
		JoySkeleton: {
			defaultProps: {
				animation: 'wave',
			},
		},
		JoyModal: {
			styleOverrides: {
				backdrop: {
					backdropFilter: 'blur(2px)',
				}
			},
		}
	},
	colorSchemes: {
		light: {
			palette: {
				secondary: {
					50: '#e3f2fd',
					100: '#bbdefb',
					200: '#90caf9',
					300: '#64b5f6',
					400: '#42a5f5',
					500: '#5a5a5a',
					600: '#1e88e5',
					700: '#1976d2',
					800: '#1565c0',
					900: '#000000',
					solidBg: '#000',
					solidColor: '#fff',
					softBg: '#eaeaea',
					softColor: '#000',
				}
			}
		}
	}
});

// export const theme: Theme = {
// 	colors: colors,
// };

