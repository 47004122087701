import API from '@/src/api';
import { trimStringEllipses } from '@/src/util';
import { Button, LinearProgress, Typography, colors } from '@mui/joy';
import { Box, Chip, Grow } from '@mui/material';
import { Listener, Media as MediaType } from '@prisma/client';
import React, { useEffect } from 'react';
import { ReactComponent as XIcon } from '@/public/icons/x.svg';
import { ReactComponent as CheckIcon } from '@/public/icons/check.svg';
import { ReactComponent  as CommentIcon } from '@/public/icons/comment.svg';
import { ReactComponent as UpArrowIcon } from '@/public/icons/up-arrow.svg';
import moment from 'moment';
import { MidDot } from './Media';
import styled from '@emotion/styled';
interface ListenerMediaCalibratorProps {
  // define your props here
  media: MediaType[];
  listener: Listener;
	onEnoughComplete: () => void;
}

const PostContainer = styled(Box)`
	background: ${colors.grey[100]};
	border: 1px solid ${colors.grey[300]};
	padding: 1rem;
	border-radius: 8px;
`;

const TextContainer = styled(Typography)`
	display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
	overflowWrap: break-word;
`;

const ListenerMediaCalibrator = (props: ListenerMediaCalibratorProps) => {
	const [index, setIndex] = React.useState(0);
	const [media, setMedia] = React.useState<MediaType[]>([]);
	const [initialNumComplete, setInitialNumComplete] = React.useState(0);

	useEffect(() => {
		setMedia(props.media.filter(m => m.calibrationResponse === null));
		setInitialNumComplete(props.media.filter(m => m.calibrationResponse !== null).length);
	}, [props.media]);

	const onRespond = async (isGood: boolean) => {
		setIndex(prev => prev + 1);
		await API.calibrateListenerFromMedia(props.listener.id, currentMedia.id, isGood);
		if (index + initialNumComplete >= 15 || (index + initialNumComplete) >= props.media.length) {
			props.onEnoughComplete();
		}
	};

	if (index >= media.length) {
		return (
			<Box marginTop={1} marginBottom={1}>
				<Typography level='title-lg'>No media to calibrate</Typography>
			</Box>
		);
	}

	const currentMedia = props.media[index];
	const post = typeof currentMedia.metadata === 'string' ? JSON.parse(currentMedia.metadata) : currentMedia.metadata;


	return (
		<Box marginTop={1} marginBottom={1}>
			<LinearProgress value={(index + initialNumComplete) / props.media.length * 100} determinate sx={{ marginBottom: '1rem' }} />

			<Grow in={true}>
				<PostContainer>
					<Box display='flex' alignItems='center' gap={0.5}>
						<Chip label={`r/${post.subreddit}`} size='small' />
						<Typography level='body-xs'>
							{moment(post.created_utc * 1000).fromNow()}
						</Typography>
						<MidDot />
						<Box display='flex' alignItems='center' gap={0.25}>
							<CommentIcon fill={colors.grey[600]} width={12} height={12} />
							<Typography level='body-xs'>{ post.num_comments }</Typography>
						</Box>
						<Box display='flex' alignItems='center' gap={0.25}>
							<UpArrowIcon fill={colors.grey[600]}  width={10} height={10} />
							<Typography level='body-xs'>{ post.score }</Typography>
						</Box>
					</Box>
					<a href={post.url} target='_blank' rel='noreferrer'>
						<Typography level='title-lg'>{post.title}</Typography>
					</a>
					<TextContainer marginTop={0.5} level='body-sm'>{post.selftext}</TextContainer>
				</PostContainer>
			</Grow>
			<Box display='flex' justifyContent='space-between' marginTop={4} gap={1}>
				<Button fullWidth onClick={() => onRespond(false)} color='danger' startDecorator={<XIcon fill='currentColor' width={12} height={12}/>}>
					Irrelevant
				</Button>
				<Button fullWidth onClick={() => onRespond(true)} color='primary' startDecorator={<CheckIcon fill='currentColor' width={14} height={14}/>}>
					Good
				</Button>
			</Box>
		</Box>
	);
};

export default ListenerMediaCalibrator;
