import styled from '@emotion/styled';
import { Box, Typography, colors } from '@mui/joy';
import { Paper } from '@mui/material';
import React from 'react';
import { useCallback } from 'react';
import { Handle, NodeProps, Position } from 'reactflow';
import { ReactComponent as WebIcon } from '@/public/icons/web.svg';

const TopPart = styled(Box)`
  background: ${colors.blue[500]};
  padding: .25rem .5rem;
`;

const Container = styled(Paper)<{ selected: boolean }>`
  padding: 0;
  overflow: hidden;
	// border-color: ${props => (props.selected ? colors.grey[600] : '#E4E4E4')};
`;

const Content = styled(Box)`
  padding: 1rem;
`;


function SourceNode(props: NodeProps) {
	console.log(props);

	const onChange = useCallback((evt) => {
		console.log(evt.target.value);
	}, []);

	return (
		<>
			<Container selected={props.selected} style={{ position: 'relative' }}>
				<TopPart>
					<Typography
						startDecorator={<WebIcon fill='currentColor' width={16} height={16} />}
						textColor='common.white'
						level='title-md'
					>
            Web Scrape
					</Typography>
				</TopPart>
				<Content>
					<Typography level='title-md'>{props.data.label}</Typography>
				</Content>
			</Container>
			<Handle type='source' position={Position.Bottom} id='a' />
		</>
	);
}

export default SourceNode;
