import styled from '@emotion/styled';
import { Avatar, Box, Button, Chip, Divider, IconButton, Input, Skeleton, Textarea, Tooltip, Typography, colors } from '@mui/joy';
import { Collapse, Paper } from '@mui/material';
import { Media as MediaType, ListenerSourceType, Keyword, Listener } from '@prisma/client';
import React, { useContext, useMemo } from 'react';
import { ReactComponent  as CommentIcon } from '@/public/icons/comment.svg';
import { ReactComponent as UpArrowIcon } from '@/public/icons/up-arrow.svg';
import { ReactComponent as HeartOutlineIcon } from '@/public/icons/heart-outline.svg';
import { ReactComponent as ViewCountIcon } from '@/public/icons/view-count.svg';
import { ReactComponent as RedditIcon } from '@/public/icons/reddit.svg';
import { ReactComponent as VerifiedIcon } from '@/public/icons/verified.svg';
import { ReactComponent as BookmarkIcon } from '@/public/icons/bookmark.svg';
import { ReactComponent as BookmarkFilledIcon } from '@/public/icons/bookmark-filled.svg';
import { ReactComponent as XIcon } from '@/public/icons/x-thin.svg';
import { ReactComponent as CheckIcon } from '@/public/icons/check.svg';
import { ReactComponent as SendIcon } from '@/public/icons/send.svg';
import { RedditPostDataType } from '@/server/types/reddit-types';
import moment from 'moment';
import { trimStringEllipses } from '@/src/util';
import { useSearchParams } from 'react-router-dom';
import API from '@/src/api';
import MarkIrrelevantModal from './MarkIrrelevantModal';
import { UserContext } from '@/src/context/UserContext';
import { ReactComponent as TwinkleIcon } from '@/public/icons/twinkle.svg';

type ListenerSourceWListener = ListenerSourceType & { listener: Listener };

interface MediaProps {
  media: MediaType & { keyword?: Keyword, listenerSource: ListenerSourceWListener };
}

interface RedditPostProps {
	post: RedditPostDataType;
	media: MediaType & { keyword?: Keyword, listenerSource: ListenerSourceWListener };
	setIsRelevant: any;
}

interface LinkedInPostProps {
	media: MediaType & { keyword?: Keyword, listenerSource: ListenerSourceWListener };
}

const MediaActions = styled(Paper)`
	position: absolute;
	top: .5rem;
	right: .5rem;
	display: flex;
	padding: .25rem;
	border-radius: 8px;
`;

const MediaText = styled(Typography)<{ isExpanded?: boolean }>`
	cursor: pointer;
	overflow: hidden;
	overflowWrap: 'break-word';
`;

const MediaMoreLink = styled.a`
	color: ${colors.blue[500]};
	cursor: pointer;
	clear: both;
  float: right;
`;

export const MediaSkeleton = () => {
	return (
		<Box sx={{ padding: '1rem', borderBottom: `1px solid ${colors.grey[200]}` }}>
			<Skeleton variant='rectangular' height={24} width={150} />
			<Skeleton variant='text' level='title-md' sx={{ width: '50%' }} />
			<Skeleton variant='rectangular' sx={{ width: '100%' }} height={42} />
		</Box>
	);
};

const Media = (props: MediaProps) => {
	const [isHovered, setIsHovered] = React.useState(false);
	const [isBookmarked, setIsBookmarked] = React.useState(props.media.isBookmarked);
	const [markIrrelevantModalOpen, setMarkIrrelevantModalOpen] = React.useState(false);
	const [isRelevant, setIsRelevant] = React.useState(true);

	const onToggleBookmark = async () => {
		setIsBookmarked(!isBookmarked);
		await API.bookmarkMedia(props.media.id, !props.media.isBookmarked);
	};

	const onMarkIrrelevant = async (reason: string) => {
		setIsRelevant(false);
		await API.markMediaIrrelevant(props.media.id, reason);
	};

	const onMarkAsResponded = async () => {
		setIsRelevant(false);
		await API.markMediaResponded(props.media.id, true);
	};

	const content = useMemo(() => {
		switch (props.media.type) {
		case 'Reddit':
			return <RedditPost media={props.media} setIsRelevant post={JSON.parse(JSON.stringify(props.media.metadata)) as RedditPostDataType} />;
		case 'X':
			return <XPost />;
		case 'Quora':
			return <LinkedInPost media={props.media} />;
		case 'LinkedIn':
			return <LinkedInPost media={props.media} />;
		default:
			return  <>---</>;
		}
	}, [props.media]);


	return (
		<Collapse in={isRelevant}>
			<Box sx={{ position: 'relative', padding: '1rem', borderBottom: `1px solid ${colors.grey[200]}` }} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
				<MarkIrrelevantModal onMarkIrrelevant={onMarkIrrelevant} open={markIrrelevantModalOpen} onClose={() => setMarkIrrelevantModalOpen(false)} />
				<PostTypeIndicator type={props.media.type} />
				{content}
				{isHovered && (
					<MediaActions>
						<Tooltip title='Mark as Responded' placement='top'>
							<IconButton onClick={onMarkAsResponded} variant='plain' size='sm' color='success'>
								<CheckIcon fill='currentColor' width={16} height={16} />
							</IconButton>
						</Tooltip>
						<Tooltip title='Mark as Irrelevant' placement='top'>
							<IconButton onClick={() => setMarkIrrelevantModalOpen(true)} variant='plain' size='sm' color='danger'>
								<XIcon width={16} height={16} fill='currentColor' />
							</IconButton>
						</Tooltip>
						<Tooltip title='Save' placement='top'>
							<IconButton onClick={onToggleBookmark} variant='plain' size='sm' color='primary'>
								{isBookmarked ? <BookmarkFilledIcon fill='currentColor' width={16} height={16} /> : <BookmarkIcon fill='currentColor' width={16} height={16} />}
							</IconButton>
						</Tooltip>
						{/* <IconButton variant='plain' size='sm'>
							<SendIcon width={16} height={16} fill='currentColor' />
						</IconButton> */}
					</MediaActions>
				)}
			</Box>
		</Collapse>
	);
};

const PostTypeIndicator = ({ type }: { type: ListenerSourceType }) => {
	const ICONS = {
		Reddit: 'Reddit',
		X: '𝕏',
		LinkedIn: 'LinkedIn',
		Quora: 'Quora',
	};

	return (
		<PostTypeContainer padding={2} top={0} right={0} display='flex' gap={0.5} alignItems='center'>
			{/* <Icon width={12} height={12} /> */}
			<Typography color='neutral' level='body-xs'>{ICONS[type]}</Typography>
		</PostTypeContainer>
	);
};

const RedditPost = (props: RedditPostProps) => {
	let { post } = props;
	if (typeof post === 'string') {
		post = JSON.parse(post);
	}
	const { isDebugMode } = useContext(UserContext);
	const [expanded, setExpanded] = React.useState(false);
	const mediaData = props.media.data as any;
	const [comment, setComment] = React.useState(mediaData?.commentSuggestion);
	//@ts-ignore
	const { containsCompanyMention } = post;


	const onPostComment = async (e: React.FormEvent) => {
		e.preventDefault();
		await API.postComment(props.media.id, comment);
		props.setIsRelevant(false);
	};

	const getColorFromRelevancyRating = (rating: number) => {
		if (rating < 0.6) {
			return 'danger';
		} else if (rating < 0.8) {
			return 'warning';
		} else {
			return 'success';
		}

	};

	return (
		<div>
			<Box display='flex' gap={.5} alignItems='center'>
				<Chip size='sm'>r/{post.subreddit}</Chip>
				{containsCompanyMention && <Chip size='sm' color='success'>Mentions You</Chip>}
				{props.media.alexScore && isDebugMode && <Chip size='sm' color={getColorFromRelevancyRating(props.media.alexScore)}>
					-
				</Chip>}
				{isDebugMode && props.media.keyword && <Chip size='sm' color='success'>{props.media.keyword?.keyword}</Chip>}
				{isDebugMode && <Chip size='sm' color='warning'>{moment(props.media.createdAt).fromNow()}</Chip>}
				<Typography level='body-xs'>
					{moment(post.created_utc * 1000).fromNow()}
				</Typography>
				<MidDot />
				<Box display='flex' alignItems='center' gap={0.25}>
					<CommentIcon fill={colors.grey[600]} width={12} height={12} />
					<Typography level='body-xs'>{ post.num_comments }</Typography>
				</Box>
				<Box display='flex' alignItems='center' gap={0.25}>
					<UpArrowIcon fill={colors.grey[600]}  width={10} height={10} />
					<Typography level='body-xs'>{ post.score }</Typography>
				</Box>
			</Box>
			{/* {isDebugMode && (
				<Typography level='body-xs'>
					{props.media.listenerSource.listener.prompt}
				</Typography>
			)} */}
			<a href={post.url} target='_blank' rel='noreferrer'>
				<Typography level='title-md'>{post.title}</Typography>
			</a>
			<Typography level='body-sm'>
				<Collapse in={expanded} collapsedSize={'2lh'}>
					<MediaText
						level='body-sm'
						onClick={() => setExpanded(!expanded)}
					>
						{/* <MediaMoreLink>more</MediaMoreLink> */}
						{mediaData?.summary?.length
							? (
								<>
									{/* <TwinkleIcon fill='currentColor' width={12} height={12} style={{ marginRight: '2px' }} /> */}
									<b>Summary </b>
									{mediaData.summary}
								</>
							)
							: post.selftext}
					</MediaText>
					{/* <form onSubmit={onPostComment}>
						<Textarea
							sx={{ marginTop: '1rem' }}
							placeholder='Add a comment...'
							// type='text'
							value={comment}
							onChange={(e) => setComment(e.target.value)}
							// endDecorator={<SendIcon width={16} height={16} fill={colors.blue[500]} />}
							// endDecorator={<Button color='primary' size='sm'>Send</Button>}
							endDecorator={
								<Button sx={{ marginLeft: 'auto' }} type='submit' variant='soft' color='primary' endDecorator={<SendIcon fill='currentColor' width={12} height={12} />}>
									Send
								</Button>
							}
						/>
					</form> */}
				</Collapse>
			</Typography>
		</div>
	);
};

const XPost = () => {
	return (
		<Box display='flex' gap={1}>
			<Avatar size='sm' />
			<div>
				<Box display='flex' gap={.5} alignItems='center'>
					<Box display='flex' gap={0.25} alignItems='center'>
						<Typography level='title-sm'>Rob Farlow</Typography>
						<VerifiedIcon width={16} height={16} />
					</Box>
					<Typography level='body-xs'>@RobFarlow</Typography>
					<MidDot />
					<Typography level='body-xs'>3 hours ago</Typography>
					<MidDot />
					<Box display='flex' alignItems='center' gap={0.25}>
						<CommentIcon fill={colors.grey[600]} width={12} height={12} />
						<Typography level='body-xs'>3</Typography>
					</Box>
					<Box display='flex' alignItems='center' gap={0.25}>
						<HeartOutlineIcon width={10} height={10} />
						<Typography level='body-xs'>12</Typography>
					</Box>
					<Box display='flex' alignItems='center' gap={0.25}>
						<ViewCountIcon width={10} height={10} />
						<Typography level='body-xs'>12</Typography>
					</Box>
				</Box>
				<Typography level='body-sm'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi, in? Placeat ad magnam quas quam ut doloribus, at iure recusandae? Aspernatur ipsum accusantium quo error quam perspiciatis dolorum sed repellat!</Typography>
			</div>
		</Box>
	);
};

const LinkedInPost = (props: LinkedInPostProps) => {
	const { media } = props;
	const metadata = media.metadata as any;
	console.log(metadata);
	return (
		<Box display='flex' gap={1}>
			<Avatar size='sm' src={metadata.img_url} />
			<a href={metadata.profile_url} target='_blank' rel='noreferrer'>
				<Box display='flex' gap={.5} alignItems='center'>
					<Typography level='title-sm'>{metadata.name}</Typography>
					<MidDot />
					<Typography level='body-xs'>{moment(metadata.date).fromNow()}</Typography>
					{/* <Box display='flex' alignItems='center' gap={0.25}>
						<CommentIcon fill={colors.grey[600]} width={12} height={12} />
						<Typography level='body-xs'>3</Typography>
					</Box>
					<Box display='flex' alignItems='center' gap={0.25}>
						<HeartOutlineIcon width={10} height={10} />
						<Typography level='body-xs'>12</Typography>
					</Box> */}
				</Box>
				<Typography level='body-xs'>{metadata.tag_line}</Typography>
				<Typography marginTop={1} level='body-sm'>
					{metadata.text}
				</Typography>
			</a>
		</Box>
	);
};

const PostTypeContainer = styled(Box)`
	position: absolute;
`;

export const MidDot = () => (
	<span style={{
		marginLeft: '.25rem',
		marginRight: '.25rem',
	}}>&middot;</span>
);


export default Media;
