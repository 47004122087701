import { Box, Checkbox, Chip, Grid, Input, Typography } from '@mui/joy';
import { Paper } from '@mui/material';
import React, { useContext, useEffect, useMemo } from 'react';
// import Media, { MediaSkeleton } from '../Media';
import styled from '@emotion/styled';
import API from '@/src/api';
import { PageContainer } from '@/src/BaseStyles';
import { UserContext } from '@/src/context/UserContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import moment from 'moment';
import DashboardHeader from './Dashboard/DashboardHeader';
import Media, { MediaSkeleton } from './Dashboard/Media';

const RightContainer = styled(Box)`
	position: sticky;
	top: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	flex-basis: 350px;
	flex-shrink: 0;
	@media (max-width: 768px) {
		display: none;
	}
`;

const Container = styled(PageContainer)`
`;

const FeedContainer = styled(Grid)`
	flex-grow: 1;
`;

const ContentContainer = styled(Box)`
	display: grid;
	grid-template-columns: 350px 1fr;
	gap: 1rem;
	align-items: flex-start;
	@media (max-width: 768px) {
		grid-template-columns: 100%;
	}
`;

const CheckBoxContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-top: .5rem;
`;

const FulfillDashboard = () => {
	const [media, setMedia] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState(true);
	const [searchParams, setSearchParams] = useSearchParams();
	const [page, setPage] = React.useState(searchParams.get('page') ? parseInt(searchParams.get('page'), 10) : 1);
	const [search, setSearch] = React.useState('');
	const [searchValue] = useDebounce(search, 750);
	const [categories, setCategories] = React.useState<any[]>(['Reddit']);
	const [categoriesValue] = useDebounce(categories, 500);
	const [afterDate, setAfterDate] = React.useState<string>(null);
	const [beforeDate, setBeforeDate] = React.useState<string>(null);

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			const data = await API.getFulfillMedia({ search: searchValue, categories: categoriesValue, afterDate, beforeDate });
			console.log(data);
			setMedia(data);
			setLoading(false);
		};
		getData();
	}, [searchValue, categoriesValue, afterDate, beforeDate]);

	const mediaRender = useMemo(() => {
		return media.map((m) => (
			<Media
				key={m.id}
				media={m}
			/> ));
	}, [media]);


	return (
		<Container>
			<DashboardHeader />
			<ContentContainer>
				<RightContainer>
					<Paper>
						<Typography level='title-lg'>Filters</Typography>
						<CheckBoxContainer>
							<Checkbox
								sx={{ flexGrow: 1 }}
								size='sm'
								checked={categories.includes('Reddit')}
								onChange={(e) => {
									if (e.target.checked) {
										setCategories([...categories, 'Reddit']);
									} else {
										setCategories(categories.filter(c => c !== 'Reddit'));
									}
								}}
								label={'Reddit'}
							/>
							<Checkbox
								sx={{ flexGrow: 1 }}
								size='sm'
								checked={categories.includes('LinkedIn')}
								disabled
								onChange={(e) => {
									if (e.target.checked) {
										setCategories([...categories, 'LinkedIn']);
									} else {
										setCategories(categories.filter(c => c !== 'LinkedIn'));
									}
								}}
								label={'LinkedIn'}
							/>
							<Checkbox
								sx={{ flexGrow: 1 }}
								size='sm'
								checked={categories.includes('Facebook')}
								disabled
								onChange={(e) => {
									if (e.target.checked) {
										setCategories([...categories, 'Facebook']);
									} else {
										setCategories(categories.filter(c => c !== 'Facebook'));
									}
								}}
								label={'Facebook'}
							/>
						</CheckBoxContainer>
						<Box display='flex' gap={1} marginTop={2}>
							<Input
								type='date'
								variant='outlined'
								sx={{ background: 'transparent', boxShadow: 'none' }}
								fullWidth
								size='sm'
								placeholder='After Date'
								onChange={(e) => setAfterDate(e.target.value)}
								value={afterDate ? moment(afterDate).format('YYYY-MM-DD') : 'After'}
							/>
							<Input
								fullWidth
								variant='outlined'
								sx={{ background: 'transparent', boxShadow: 'none' }}
								size='sm'
								type='date'
								placeholder='Before Date'
								onChange={(e) => setBeforeDate(e.target.value)}
								value={beforeDate ? moment(beforeDate).format('YYYY-MM-DD') : 'Before'}
							/>
						</Box>
					</Paper>
				</RightContainer>
				<FeedContainer>
					<Box display='flex' gap={0.5} paddingBottom={1}>
						<Input
							placeholder='Add a new topic...'
							variant='outlined'
							fullWidth
							size='lg'
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							startDecorator={<Chip color='primary' size='sm'>AI Search</Chip>}
						/>
					</Box>
					<Paper style={{ padding: 0 }}>
						<Box display='flex' flexDirection='column'>
							{loading ? (
								<>
									<MediaSkeleton />
									<MediaSkeleton />
									<MediaSkeleton />
									<MediaSkeleton />
									<MediaSkeleton />
									<MediaSkeleton />
									<MediaSkeleton />
								</>
							) : (
								<>
									{mediaRender}
								</>
							)}
						</Box>
					</Paper>
					{/* <Box display='flex' gap={0.5} justifyContent='space-between' paddingTop={1}>
						<Button disabled={page === 1} variant='soft' color='primary' onClick={() => setPage(page - 1)}>Previous</Button>
						<Button disabled={media.length < 25} variant='soft' color='primary' onClick={() => setPage(page + 1)}>Next</Button>
					</Box> */}
				</FeedContainer>
			</ContentContainer>
		</Container>
	);
};

export default FulfillDashboard;
