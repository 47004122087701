import React, { useContext } from 'react';
import { ListenerSource, Listener as ListenerType, OutputChannel } from '@prisma/client';
import { Avatar, AvatarGroup, Box, CircularProgress, Dropdown, IconButton, Menu, MenuButton, MenuItem, Skeleton, Tooltip, Typography } from '@mui/joy';
import { ReactComponent as WebsiteIcon } from '@/public/icons/website.svg';
import { ReactComponent as ThreeDots } from '@/public/icons/threedots.svg';
import { ReactComponent as MegaphoneIcon } from '@/public/icons/megaphone.svg';
import { ReactComponent as DeepSearchIcon } from '@/public/icons/deepsearch.svg';
import { ReactComponent as SlackIcon } from '@/public/icons/slack.svg';
import { ReactComponent as BarChartIcon } from '@/public/icons/barchart.svg';
import styled from '@emotion/styled';
import moment from 'moment';
import { ReactComponent as RedditIcon } from '@/public/icons/reddit.svg';
import { ReactComponent as XTwitterIcon } from '@/public/icons/xtwitter.svg';
import { ReactComponent as QuoraIcon } from '@/public/icons/quora.svg';
import { ReactComponent as LinkedInIcon } from '@/public/icons/linkedin.svg';
import { ListenerSources } from './AddListenerModal';
import { formatUrlWithoutProtocol } from '@/server/util';
import API from '@/src/api';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '@/src/context/UserContext';

const IconContainer = styled.div`
	background-color: black;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 6px;
	width: 32px;
	height: 32px;
	color: white;
	flex-shrink: 0;
`;

const ItemContainer = styled(Box)`
	display: flex;
	align-items: center;
	padding: 1rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	height: 100%;
`;

const PromptTypograhpy = styled(Typography)`
		-webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
`;

const HideOnMobile = styled.div`
	@media (max-width: 768px) {
		display: none;
	}
`;

export const OBJECTIVE_DETAILS = {
	Monitor: {
		icon: WebsiteIcon,
		title: 'Monitor',
		description: 'Keep up to date with relevant discussions',
	},
	DeepSearch: {
		icon: DeepSearchIcon,
		title: 'Deep Search',
		description: 'Find, analyze and engage with your audience',
	},
	Traffic: {
		icon: WebsiteIcon,
		title: 'Traffic',
		description: 'Get relevant traffic to your site',
	},
	BrandAwareness: {
		icon: MegaphoneIcon,
		title: 'Brand Awareness',
		description: 'Increase the visibility of your brand',
	},
	MarketResearch: {
		icon: BarChartIcon,
		title: 'Market Research',
		description: 'Understand your target audience',
	},
};

export type ListenerTypeWithSources = ListenerType & {
	sources: ListenerSource[];
	outputChannel: OutputChannel;
};


export const ListenerRow = ({ listener, loading, onDeleted }: { listener?: ListenerTypeWithSources, loading?: boolean, onDeleted?: (id: number) => void }) => {
	const navigate = useNavigate();
	const { isDebugMode } = useContext(UserContext);

	if (loading) {
		return (
			<>
				<ItemContainer>
					<Box display='flex' gap={1} alignItems='center' width='100%'>
						<HideOnMobile>
							<Skeleton variant='rectangular' width={32} height={32} />
						</HideOnMobile>
						<Box display='flex' flexDirection='column' alignItems='start' width='100%'>
							<Skeleton sx={{ position: 'relative' }} variant='text' level='title-md' width={130} />
							<Skeleton sx={{ position: 'relative', width: '100%' }} height={36} />
						</Box>
					</Box>
				</ItemContainer>
				<HideOnMobile>
					<ItemContainer style={{ justifyContent: 'center' }}>
						<AvatarGroup>
							<Avatar size='sm'>
								<Skeleton variant='circular' width={32} height={32} />
							</Avatar>
						</AvatarGroup>
					</ItemContainer>
				</HideOnMobile>
				<HideOnMobile>
					<ItemContainer style={{ justifyContent: 'center' }}>
						<div>
							<Skeleton variant='circular' width={32} height={32} />
						</div>
					</ItemContainer>
				</HideOnMobile>

				<ItemContainer>
					<Box display='flex' justifyContent='flex-end'>
						<IconButton variant='soft' size='sm'>
							<ThreeDots width={14} height={14} />
						</IconButton>
					</Box>
				</ItemContainer>
			</>
		);
	}

	const onDelete = async () => {
		if (onDeleted) {
			onDeleted(listener.id);
		}
		await API.deleteListener(listener.id);
	};

	const onViewPosts = () => {
		navigate(`/?listeners=${listener.id}`);
	};

	const [isPaused, setIsPaused] = React.useState(listener.isPaused);

	const onPause = async () => {
		setIsPaused(!isPaused);
		await API.updateListener(listener.id, { isPaused: !isPaused });
	};

	const Icon = OBJECTIVE_DETAILS[listener.objective].icon;
	const title = formatUrlWithoutProtocol(listener.sourceUrl) || listener.title;
	return (
		<>
			<ItemContainer>
				<Box display='flex' gap={1} alignItems='center'>
					<HideOnMobile>
						<IconContainer>
							<Icon fill='currentColor' width={18} height={18} />
						</IconContainer>
					</HideOnMobile>
					<Box display='flex' flexDirection='column' alignItems='start'>
						<Typography whiteSpace='nowrap' level='title-md'>
							{title}
							{isPaused && <Typography level='body-xs' color='danger'> PAUSED</Typography>}
							{isDebugMode && <Typography level='body-xs' color={listener.isCalibrated ? 'primary' : 'warning'}>{' '}{listener.isCalibrated ? 'CALIBRATED' : 'UNCALIBRATED'}</Typography>}
						</Typography>
						<Tooltip title={<Box sx={{ maxWidth: '250px' }}>{listener.prompt}</Box>} size='sm' variant='solid' placement='bottom'>
							<PromptTypograhpy level='body-xs'>{listener.prompt}</PromptTypograhpy>
						</Tooltip>
					</Box>
				</Box>
			</ItemContainer>
			<HideOnMobile>
				<ItemContainer style={{ justifyContent: 'center' }}>
					<AvatarGroup>
						{listener.sources.map((source) => {
							const Icon = ListenerSources.find(s => s.name === source.type)?.icon;
							return (
								<Avatar key={source.id} size='sm'>
									<Icon width={32} height={32} />
								</Avatar>
							);
						})}
					</AvatarGroup>
				</ItemContainer>
			</HideOnMobile>
			<HideOnMobile>
				<ItemContainer style={{ justifyContent: 'center' }}>
					{listener.outputChannel ? (
						<Tooltip title={listener.outputChannel.name} size='md' variant='solid' placement='top'>
							<Avatar size='sm'>
								<SlackIcon width={22} height={22} />
							</Avatar>
						</Tooltip>
					) : (
						<Typography level='body-xs'>-</Typography>
					)}
				</ItemContainer>
			</HideOnMobile>
			<ItemContainer>
				<Box display='flex' justifyContent='flex-end'>
					<Dropdown>
						<MenuButton
							slots={{ root: IconButton }}
							slotProps={{ root: { variant: 'soft', size: 'sm' } }}
						>
							<ThreeDots width={14} height={14} />
						</MenuButton>
						<Menu size='sm'>
							<MenuItem onClick={onViewPosts}>View posts</MenuItem>
							<MenuItem onClick={onPause}>
								{isPaused ? 'Unpause' : 'Pause'}
							</MenuItem>
							<MenuItem onClick={onDelete} color='danger'>Delete</MenuItem>
						</Menu>
					</Dropdown>
				</Box>
			</ItemContainer>
		</>
	);
};

export const ListenerHeaderRow = () => {
	return (
		<>
			<ItemContainer style={{ paddingBottom: '0.5rem' }}>
				<Typography level='body-xs'>Title</Typography>
			</ItemContainer>
			<HideOnMobile>
				<ItemContainer style={{ paddingBottom: '0.5rem' }}>
					<Typography level='body-xs'>Monitoring</Typography>
				</ItemContainer>
			</HideOnMobile>
			<HideOnMobile>
				<ItemContainer style={{ paddingBottom: '0.5rem' }}>
					<Typography level='body-xs'>Output</Typography>
				</ItemContainer>
			</HideOnMobile>
			<ItemContainer style={{ paddingBottom: '0.5rem' }}>
				<Box display='flex' justifyContent='flex-end'>

				</Box>
			</ItemContainer>
		</>
	);
};

export default ListenerRow;
