import API from '@/src/api';
import { UserContext } from '@/src/context/UserContext';
import styled from '@emotion/styled';
import { Avatar, Box, Button, Skeleton, Typography } from '@mui/joy';
import React, { useContext } from 'react';

const UserContainer = styled.div`
  display: flex;
  gap: .5rem;
  margin-top: .5rem;
	align-items: center;
`;

const Account: React.FC = () => {
	const { user, loaded } = useContext(UserContext);

	const onSignout = async () => {
		await API.logout();
		window.location.href = '/';
	};

	if (!loaded) {
		return (
			<div>
				<UserContainer>
					<Skeleton sx={{ flexShrink: 0 }} variant='circular' width={32} height={32} />
					<Box>
						<Skeleton variant='text' height={24} width={100} />
						<Skeleton variant='text' height={18} width={150} />
					</Box>
				</UserContainer>
			</div>
		);
	}

	return (
		<div>
			<Box display='flex' gap={1} justifyContent='space-between' alignItems='flex-end'>
				<UserContainer>
					<Avatar size='sm' src={user.imageUrl} />
					<Box>
						<Typography level='title-md'>{user.name}</Typography>
						<Typography level='body-xs'>{user.email}</Typography>
					</Box>
				</UserContainer>
				<Button onClick={onSignout} color='neutral' variant='soft'>Sign out</Button>
			</Box>
		</div>
	);
};

export default Account;
