import React, { useContext } from 'react';
import { Box, Button, DialogContent, Input, Modal, ModalDialog, Textarea, Typography, colors } from '@mui/joy';
import styled from '@emotion/styled';
import API from '@/src/api';
import { ReactComponent as ArrowRightIcon } from '@/public/icons/arrow-right.svg';
// import { ListenerSourceType, ObjectiveType } from '@prisma/client';
import OutputChannels from './OutputChannels';
import { ReactComponent as Icon } from '@/public/icon.svg';
import { UserContext } from '@/src/context/UserContext';

interface OnboardingModalProps {
	open: boolean;
	onClose: () => void;
}

const StepDot = styled.div<{ completed: boolean }>`
	width: 12px;
	height: 12px;
	border-radius: 12px;
	background-color: ${(props) => (props.completed ? 'black' : colors.grey[300])};
`;


const DisplayBox = styled(Box)`
  background-color: ${colors.grey[100]};
  border-radius: 5px;
  padding: 1rem;
  width: 100%;
  height: 200px;
  border: 1px solid ${colors.grey[200]};
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
`;

const Form = styled.form`
  display: flex;
  justify-content: center;
`;


const OnboardingModal = (props: OnboardingModalProps) => {
	const [step, setStep] = React.useState(0);
	const [url, setUrl] = React.useState('');
	const [prompt, setPrompt] = React.useState('');
	const [loading, setLoading] = React.useState('');
	const [accountsConnected, setAccountsConnected] = React.useState(false);
	const { listeners, setListeners } = useContext(UserContext);

	const onStepSubmit = async (e?: any) => {
		e?.preventDefault();
		if (step === 0) {
			setStep(1);
		} else if (step === 1) {
			setLoading('Scanning website');
			const generatedPrompt = await API.generatePromptFromUrl(url);
			setPrompt(generatedPrompt);
			setLoading('');
			setStep(2);
		} else if (step === 2) {
			setLoading('Creating listener');
			const newListener = await API.createListener({ url, prompt, objective: 'Traffic', sources: ['Reddit'] });
			setListeners([...listeners, newListener]);
			setLoading('');
			setStep(3);
		} else {
			props.onClose();
		}
	};

	return (
		<Modal open={props.open} onClose={props.onClose}>
			<ModalDialog>
				<Box display='flex' gap={2} justifyContent='center' marginBottom={0.5}>
					<StepDot completed={step >= 0} />
					<StepDot completed={step >= 1} />
					<StepDot completed={step >= 2} />
					<StepDot completed={step >= 3} />
				</Box>
				<DisplayBox>
					{step >= 0 && (
						<>
							<Icon width={92} height={92} />
						</>
					)}
				</DisplayBox>
				<DialogContent sx={{ width: '400px', maxWidth: '100%' }}>
					{step === 0 && (
						<Box>
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='center'
								alignItems='center'
								marginBottom={1}
								marginTop={3}
							>
								<Typography textAlign='left' level='h4'>Connect your accounts</Typography>
								<Typography textAlign='left' level='body-sm'>Connect your Slack and Reddit accounts to get started</Typography>
							</Box>
							<OutputChannels onConnected={() => setAccountsConnected(true)} />
						</Box>
					)}
					{step === 1 && (
						<>
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='center'
								alignItems='center'
								marginBottom={1}
								marginTop={3}
							>
								<Typography textAlign='left' level='h4'>What is your website?</Typography>
								<Typography textAlign='left' level='body-sm'>Add your website to start tracking discussions</Typography>
							</Box>
							<Form onSubmit={onStepSubmit}>
								<Input
									required
									sx={{ minWidth: '250px' }}
									placeholder='https://example.com'
									value={url}
									onChange={(e) => setUrl(e.target.value)}
								/>
							</Form>
						</>
					)}
					{step === 2 && (
						<Box>
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='center'
								alignItems='center'
								marginBottom={1}
								marginTop={3}
							>
								<Typography textAlign='center' level='h4'>Describe discussion topics to listen to</Typography>
								<Typography textAlign='center' level='body-sm'>For example, people asking how or where to play virtual team building games</Typography>
							</Box>
							<Textarea
								required
								minRows={3}
								maxRows={6}
								size='sm'
								placeholder='People looking for...'
								value={prompt}
								onChange={(e) => setPrompt(e.target.value)}
							/>
						</Box>
					)}
					{step === 3 && (
						<Box>
							<Box
								display='flex'
								flexDirection='column'
								justifyContent='center'
								alignItems='center'
								marginBottom={1}
								marginTop={3}
							>
								<Typography textAlign='center' level='h4'>All set</Typography>
								<Typography textAlign='center' level='body-sm'>Your feed will be updated with relevant discussions</Typography>
							</Box>
						</Box>
					)}
				</DialogContent>
				<Box marginTop={4} display='flex' justifyContent='flex-end' alignItems='center'>
					<Box display='flex' gap={1}>
						{step > 0 && (
							<Button variant='outlined' color='neutral' onClick={() => setStep(step - 1)}>
                Back
							</Button>
						)}
						<Button
							endDecorator={
								<ArrowRightIcon
									fill='currentColor'
									width={12}
									height={12}
								/>
							}
							onClick={onStepSubmit}
							variant='solid'
							color='primary'
							loading={Boolean(loading)}
							loadingPosition='end'
							disabled={Boolean(loading) || (step === 0 && !accountsConnected) || (step === 1 && !url) || (step === 2 && (!prompt || prompt.length < 10))}
						>
							{loading ? (loading) : (
								<>
									{step === 2 ? 'Finish' : 'Next'}
								</>
							) }
						</Button>
					</Box>

				</Box>
			</ModalDialog>
		</Modal>
	);
};

export default OnboardingModal;
