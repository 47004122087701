import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as XTwitter } from '@/public/icons/xtwitter.svg';

const StyledFooter = styled.footer`
  display: flex;
  background-color: black;
  padding: 2rem;
`;

const FooterContent = styled.div`
  max-width: 100%;
  width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LeftContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const LinksContainer = styled.div`
  display: flex;
  gap: 1rem;
  color: white;
  a {
    color: white;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
  }
`;


const Footer: React.FC = () => {
	return (
		<StyledFooter>
			<FooterContent>
				<LeftContainer>
					<a href='/'>
						<Typography variant='h6' color='white' sx={{ textDecoration: 'none' }}>FeedFlow</Typography>
					</a>
					{/* <a href="https://twitter.com/RobFarlow" target="_blank" rel="noreferrer">
						<XTwitter width={18} height={18} fill="white" />
					</a> */}
				</LeftContainer>
				<LinksContainer>
					<a href='/landing'>Home</a>
					<a href='/pricing'>Pricing</a>
					<a href='/privacy'>Privacy</a>
					<a href='/login'>Login</a>
				</LinksContainer>
			</FooterContent>
		</StyledFooter>
	);
};

export default Footer;
