import React, { useContext } from 'react';
import LandingPage from './pages/LandingPage';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/Dashboard';
import { UserContext } from './context/UserContext';
import Pricing from './pages/Pricing';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import API from './api';
import { Provider, ErrorBoundary } from '@rollbar/react';
import Rollbar from 'rollbar';
import Listeners from './pages/Dashboard/Configuration';
import Team from './pages/Dashboard/sections/Team';
import Feed from './pages/Dashboard/sections/Feed';
import Admin from './pages/Dashboard/sections/Admin';
import Configuration from './pages/Dashboard/Configuration';
import { Button, Typography } from '@mui/joy';
import styled from '@emotion/styled';
import RFPFeed from './pages/RFPDashboard';
import AppConfigurationProvider, { AppConfigurationContext } from './context/AppContext';
import ConfigurationV2 from './pages/ConfigurationV2/ConfigurationV2';

const ViewAsContainer = styled.div`
	position: fixed;
	bottom: 1rem;
	left: 1rem;
	border: 1px dashed black;
	background: white;
	border-radius: 8px;
	padding: 1rem;
	background-color: white;
	z-index: 1000;
	box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
`;


const App = () => {
	const configuration = useContext(AppConfigurationContext);
	const user = useContext(UserContext);
	const [searchParams, _] = useSearchParams();

	const userWasLoaded = localStorage.getItem('user_loaded') === 'true' || searchParams.get('login') === 'true';

	const rollbarConfig: Rollbar.Configuration = {
		accessToken: 'eefc00eba2c64d4a9dee975af8a7bca9',
		captureUncaught: true,
		captureUnhandledRejections: true,
		environment: ENVIRONMENT,
		payload: {
			person: {
				id: user?.user?.id,
				username: user?.user?.email,
				email: user?.user?.email,
			},
		},
	};

	return (
		<Provider config={rollbarConfig}>
			<ErrorBoundary>
				{user.isViewAs && (
					<ViewAsContainer>
						<Typography color='danger' level='title-lg'>Viewing as</Typography>
						<Typography level='body-sm'> {user.user.email}</Typography>
						<a onClick={API.clearViewAs} style={{ cursor: 'pointer' }}>
							<Typography color='primary' level='body-xs'>Clear</Typography>
						</a>
						{/* You are viewing as {user.user.email} <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={API.clearViewAs}>Clear</a> */}
					</ViewAsContainer>
				)}
				<Routes>
					{(!user.loaded && userWasLoaded) || (user.loaded && user?.user) ? (
						<>
							{Object.keys(configuration.routes).map((path) => <Route key={path} path={path} element={configuration.routes[path]}  />)}
							{configuration.isConfigurable && <Route path='/config' element={<ConfigurationV2 />} /> }
							{user?.user?.isAdmin && (
								<Route path='/admin' element={<Admin />} />
							)}
						</>
					) : (
						<Route path='/' element={<LoginPage />} />
					)}


					<Route path='/login' element={<LoginPage />} />
					<Route path='/pricing' element={<Pricing />} />
					<Route path='/privacy' element={<PrivacyPolicyPage />} />
					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			</ErrorBoundary>
		</Provider>
	);
};

export default App;
