import { Typography, Chip, Box, colors } from '@mui/joy';
import moment from 'moment';
import React from 'react';
import { MidDot } from '../Dashboard/Media';
import { trimStringEllipses } from '@/src/util';
import styled from '@emotion/styled';
import { TempProducts } from '@prisma/client';

interface ProductMediaProps {
  product: TempProducts;
}

const TopContainer = styled(Box)`
	@media (max-width: 768px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;


const ProductMedia = (props: ProductMediaProps) => {
	const { product } = props;

	const productHuntData = product.productHuntData as any;
	// get just the protocol and domain
	const url = new URL(product.url);
	const origin = url.origin;
	const faviconUrl = product.faviconUrl.startsWith('http') ? product.faviconUrl : `${origin}${product.faviconUrl || '/favicon.ico'}`;


	return (
		<div>
			<Box sx={{ position: 'relative', padding: '1rem', borderBottom: `1px solid ${colors.grey[200]}` }}>
				{/* <Box display='flex' gap={0.5}>
					<Chip size='sm'><b>Category</b> {rfp.category}</Chip>
					<Chip size='sm'><b>Organization</b> {rfp.organization}</Chip>
				</Box> */}
				<Box display='flex' gap={1}>
					<img src={faviconUrl} alt='' style={{ width: '32px', height: '32px', marginTop: '4px' }} />
					<TopContainer>
						{/* <Typography level='title-sm'>Due {closingDate}</Typography> */}
						{/* <Chip size='sm'><b>Category</b> {rfp.category}</Chip>
            <Chip size='sm'><b>Organization</b> {rfp.organization}</Chip> */}
						{/* <Typography level='body-xs'>
              {'2 hours ago'}
            </Typography>
            <MidDot />
            <Box display='flex' alignItems='center' gap={0.25}>
              <CommentIcon fill={colors.grey[600]} width={12} height={12} />
              <Typography level='body-xs'>{ 4 }</Typography>
            </Box>
            <Box display='flex' alignItems='center' gap={0.25}>
              <UpArrowIcon fill={colors.grey[600]}  width={10} height={10} />
              <Typography level='body-xs'>{ 4 }</Typography>
            </Box> */}
						<a href={product.url} target='_blank' rel='noreferrer'>
							<Typography level='title-md'>{productHuntData.title}</Typography>
						</a>
						<Typography level='body-xs'>
							{product.pageDescription || productHuntData.description}
						</Typography>
					</TopContainer>
				</Box>
				{/* <Typography level='body-sm'>
					<b>{openDate}</b> <MidDot/> <span dangerouslySetInnerHTML={{ __html: trimStringEllipses(rfp.description || 'no description', 400) }} />
				</Typography> */}
			</Box>
		</div>
	);
};

export default ProductMedia;
