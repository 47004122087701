import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Chip, IconButton, Typography } from '@mui/joy';
import { ReactComponent as FeedFlowIcon } from '@/public/icon.svg';
import { UserContext } from '@/src/context/UserContext';
import { Paper } from '@mui/material';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { ReactComponent as BugIcon } from '@/public/icons/bug.svg';
import { ReactComponent as SettingsIcon } from '@/public/icons/settings.svg';
import { AppConfigurationContext } from '@/src/context/AppContext';


const HeaderWrapper = styled(Paper)`
  display: flex;
  align-items: center;
  padding: .5rem;
  gap: 1rem;
  justify-content: space-between;
	background-color: white;
  margin: 0 auto;
  background: white;
  padding: 1rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0;
  margin: 0 auto;
`;

const ButtonsContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;

export enum ConfigurationTabs {
  Sources,
  Filtering,
  Dashboard,
}

interface ConfigurationHeaderProps {
  tab: ConfigurationTabs;
  onTabChange: (tab: ConfigurationTabs) => void;
}

const ConfigurationHeader= (props: ConfigurationHeaderProps) => {
	const configuration = useContext(AppConfigurationContext);

	return (
		<Box display='flex' justifyContent='center'>
			<HeaderWrapper>
				<ButtonsContainer>
					{/* <Button size='sm' color='secondary' onClick={() => props.onTabChange(ConfigurationTabs.Sources)} variant={props.tab === ConfigurationTabs.Sources ? 'solid' : 'soft'}>Sources</Button> */}
					<Button size='sm' color='secondary' onClick={() => props.onTabChange(ConfigurationTabs.Filtering)} variant={props.tab === ConfigurationTabs.Filtering ? 'solid' : 'soft'}>Logic</Button>
					<Button size='sm' color='secondary' onClick={() => props.onTabChange(ConfigurationTabs.Dashboard)} variant={props.tab === ConfigurationTabs.Dashboard ? 'solid' : 'soft'}>Pages</Button>
				</ButtonsContainer>
			</HeaderWrapper>
		</Box>
	);
};

export default ConfigurationHeader;
