import styled from '@emotion/styled';

const Main = styled.main`
	padding: 2rem;
	max-width: 100%;
	box-sizing: border-box;
	width: 1400px;
	margin: 0 auto;
	margin-top: 4rem;
	overflow-x: hidden;
`;

export default Main;
