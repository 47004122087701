import { UserContext } from '@/src/context/UserContext';
import styled from '@emotion/styled';
import { Avatar, Box, Skeleton, Typography } from '@mui/joy';
import React, { useContext } from 'react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // gap: .5rem;
  margin-top: .5rem;
`;

const Usage: React.FC = () => {
	const { user, loaded, plan, ...userData } = useContext(UserContext);

	if (!loaded) {
		return (
			<Container>
				{/* <Skeleton sx={{ flexShrink: 0 }} variant='circular' width={32} height={32} /> */}
				<Box>
					<Skeleton variant='text' level='title-sm' width={130} />
					<Skeleton variant='text' level='title-sm' width={150} />
				</Box>
			</Container>
		);
	}

	return (
		<Container>
			{/* <Typography level='title-lg'>{plan?.plan}</Typography> */}
			<Box display='flex' justifyContent='space-between'>
				<Typography level='title-md'>Listeners</Typography>
				<Typography color='neutral' level='title-sm'>{userData.numberOfListenersUsed}</Typography>
			</Box>
			<Box display='flex' justifyContent='space-between'>
				<Typography level='title-md'>Posts</Typography>
				<Typography color='neutral' level='title-sm'>{userData.numberOfPostsThisPeriod}</Typography>
			</Box>
		</Container>
	);
};

export default Usage;
