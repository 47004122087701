import styled from '@emotion/styled';
import React, { useContext, useEffect } from 'react';
import { Box, Button, Typography, colors } from '@mui/joy';
import { Paper } from '@mui/material';
import ListenerRow, { ListenerHeaderRow } from './ListenerRow';
import { ReactComponent as PlusIcon } from '@/public/icons/plus.svg';
import AddListenerModal from './AddListenerModal';
import OutputChannels from './OutputChannels';
import Account from './Account';
import { PageContainer } from '@/src/BaseStyles';
import DashboardHeader from './DashboardHeader';
import Usage from './sections/Usage';
import OnboardingModal from './OnboardingModal';
import { UserContext } from '@/src/context/UserContext';

const ListenerContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 0fr 0fr 0fr;
	@media (max-width: 768px) {
		grid-template-columns: 1fr 0fr;
	}
	& > * {
		border-bottom: 1px solid ${colors.grey[200]};
	}
`;

const SectionContainer = styled(Box)`
	flex-grow: 1;
	// margin-top: 2rem;
	display: grid;
	align-items: baseline;
	grid-template-columns: 350px auto;
	grid-gap: 1rem;
	@media (max-width: 900px) {
		grid-template-columns: 100%;
	}
`;

const Container = styled(PageContainer)`
`;

const TopContainer = styled(Box)`
	display: flex;
	justify-content: space-between;
`;

const BottomContainer = styled(Box)`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	@media (max-width: 768px) {
		grid-template-columns: 100%;
	}
`;


const Configuration = () => {
	const [addListenerModalOpen, setAddListenerModalOpen] = React.useState(false);
	const [addListenerListener, setAddListenerListener] = React.useState(null);
	const [onboardingModalOpen, setOnboardingModalOpen] = React.useState(false);
	// const [listeners, setListeners] = React.useState<ListenerTypeWithSources[]>([]);
	// const [loading, setLoading] = React.useState(true);
	const { listeners, setListeners, loaded, outputChannels } = useContext(UserContext);

	const onDeleted = (id: number) => {
		setListeners(listeners.filter((listener) => listener.id !== id));
	};

	useEffect(() => {
		if (loaded && !listeners?.length) {
			setOnboardingModalOpen(true);
		}
		const uncalibratedListeners = listeners?.filter((listener) => !listener.isPaused && !listener.isCalibrated);
		if (uncalibratedListeners?.length) {
			setAddListenerListener(uncalibratedListeners[0]);
		}
	}, [loaded, listeners]);

	useEffect(() => {
		if (addListenerListener) {
			console.log({ addListenerListener });
			setAddListenerModalOpen(true);
		}
	}, [addListenerListener]);

	const onOnboardingClose = () => {
		const redditAccount = outputChannels?.find((account) => account.type === 'Reddit');
		const slackAccount = outputChannels?.find((account) => account.type === 'Slack');
		if (redditAccount && slackAccount) {
			setOnboardingModalOpen(false);
		}
	};


	return (
		<>
			<AddListenerModal
				open={addListenerModalOpen}
				listener={addListenerListener}
				onClose={() => setAddListenerModalOpen(false)}
			/>
			<OnboardingModal open={onboardingModalOpen} onClose={onOnboardingClose} />
			<Container>
				<DashboardHeader />
				<SectionContainer>
					<BottomContainer>
						<Paper>
							<TopContainer>
								<Typography level='h3'>Connections</Typography>
							</TopContainer>
							<OutputChannels />
						</Paper>
						<Paper>
							<TopContainer>
								<Typography level='h3'>Activity</Typography>
							</TopContainer>
							<Usage />
						</Paper>
						<Paper>
							<TopContainer>
								<Typography level='h3'>Account</Typography>
							</TopContainer>
							<Account />
						</Paper>
					</BottomContainer>
					<Paper>
						<TopContainer>
							<Typography level='h3'>Listeners</Typography>
							<Button onClick={() => setAddListenerModalOpen(true)} color='primary' variant='plain' startDecorator={<PlusIcon width={12} height={12} fill='currentColor' />}>
							Add
							</Button>
						</TopContainer>
						<ListenerContainer>
							<ListenerHeaderRow />
							{!loaded && (
								<>
									<ListenerRow loading />
									<ListenerRow loading />
									<ListenerRow loading />
								</>
							)}
							{listeners?.map((listener) => (
								<ListenerRow key={listener.id} onDeleted={onDeleted} listener={listener} />
							))}
						</ListenerContainer>
					</Paper>
				</SectionContainer>
			</Container>
		</>
	);
};

export default Configuration;
