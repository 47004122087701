import styled from '@emotion/styled';
import { Box, Button, Skeleton, Typography } from '@mui/joy';
import React, { useContext, useEffect } from 'react';
import { ReactComponent as SlackIcon } from '@/public/icons/slack.svg';
import { ReactComponent as RedditIcon } from '@/public/icons/reddit.svg';
import API from '@/src/api';
import { UserContext } from '@/src/context/UserContext';
import { trimStringEllipses } from '@/src/util';

const AccountRow = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding:.5rem;
`;

const AccountTitleContainer = styled(Box)`
	display: flex;
	align-items: center;
	gap: .5rem;
`;

interface OutputChannelsProps {
  onConnected?: () => void;
}


const OutputChannels = (props: OutputChannelsProps) => {
	const { loaded, outputChannels } = useContext(UserContext);
	const [redditAccount, setRedditAccount] = React.useState(null);
	const [slackAccount, setSlackAccount] = React.useState(null);

	useEffect(() => {
		setRedditAccount(outputChannels?.find((account) => account.type === 'Reddit'));
		setSlackAccount(outputChannels?.find((account) => account.type === 'Slack'));
	}, [outputChannels]);

	useEffect(() => {
		if (redditAccount && slackAccount && props.onConnected) {
			props.onConnected();
		}
	}, [redditAccount, slackAccount]);

	return (
		<Box marginTop={1}>
			<AccountRow>
				<AccountTitleContainer>
					<SlackIcon width={32} height={32} />
					<Box>
						<Typography level='title-md'>Slack</Typography>
						{!loaded ? (
							<Skeleton variant='text' level='body-sm' width='100px' />
						) : (
							<Typography level='body-sm'>
								{slackAccount?.name ? 'Connected' : 'Not connected'}
							</Typography>
						)}
					</Box>
				</AccountTitleContainer>
				{loaded && (
					<a href='/api/auth/slack/oauth'>
						<Button color={slackAccount ? 'neutral' : 'primary'} variant={slackAccount ? 'outlined' : 'solid'}>
							{slackAccount ? slackAccount.name : 'Connect'}
						</Button>
					</a>
				)}
			</AccountRow>
			<AccountRow>
				<AccountTitleContainer>
					<RedditIcon width={32} height={32} />
					<Box>
						<Typography level='title-md'>Reddit</Typography>
						{!loaded ? (
							<Skeleton variant='text' level='body-sm' width='100px' />
						) : (
							<Typography level='body-sm'>
								{redditAccount?.name ? 'Connected' : 'Not connected'}
							</Typography>
						)}
					</Box>
				</AccountTitleContainer>
				{loaded && (
					<a href='/api/auth/reddit'>
						<Button color={redditAccount ? 'neutral' : 'primary'} variant={redditAccount ? 'outlined' : 'solid'} sx={{ whiteSpace: 'nowrap' }}>
							{redditAccount ? `u/${trimStringEllipses(redditAccount.name, 14)}` : 'Connect'}
						</Button>
					</a>
				)}
			</AccountRow>
		</Box>
	);
};

export default OutputChannels;
