import { OutputChannel, Plan, PlanMember, User } from '@prisma/client';
import React, { createContext, useEffect, useState } from 'react';
import API from '../api';
import posthog from 'posthog-js';
import moment from 'moment';

// Create the UserContext
export const UserContext = createContext<UserContextType>(null);

interface UserContextType {
	loaded: boolean;
	user: User | null;
	isDebugMode?: boolean;
	isViewAs?: boolean;
	isPlanActive?: boolean;
	numberOfListenersUsed?: number;
	numberOfPostsThisPeriod?: number;
	plan?: Plan & { members:  (PlanMember & {user: User})[] };
	listeners?: any;
	setListeners?: (listeners: any) => void;
	setIsDebugMode?: (isDebugMode: boolean) => void;
	outputChannels?: OutputChannel[];
	mediaCountForListener?: any;
}

// Create the UserProvider component
export const UserProvider = ({ children }: { children: React.ReactNode }) => {
	const [user, setUser] = useState<UserContextType>({
		loaded: false,
		user: null,
	});
	const [isDebugMode, _setIsDebugMode] = useState(localStorage.getItem('debug_mode') === 'true');

	useEffect(() => {
		if (isDebugMode) {
			localStorage.setItem('debug_mode', 'true');
		} else {
			localStorage.removeItem('debug_mode');
		}
	}, [isDebugMode]);


	const setListeners = (listeners) => {
		setUser((prev) => ({
			...prev,
			listeners,
		}));
	};

	const setIsDebugMode = (isDebugMode: boolean) => {
		_setIsDebugMode(isDebugMode);
		setUser((prev) => ({
			...prev,
			isDebugMode,
		}));
	};

	useEffect(() => {
		API.getUserFromSession().then((userData) => {
			console.log({ userData });
			if (userData?.user?.email) {
				localStorage.setItem('user_loaded', 'true');
			} else {
				localStorage.removeItem('user_loaded');
			}
			setUser({
				loaded: true,
				user: userData.user,
				isDebugMode,
				isViewAs: document.cookie.includes('view-as'),
				isPlanActive: moment().isBefore(userData?.plan?.activeUntil),
				numberOfListenersUsed: userData.numberOfListenersUsed,
				numberOfPostsThisPeriod: userData.numberOfPostsThisPeriod,
				plan: userData.plan,
				listeners: userData.listeners,
				outputChannels: userData.outputChannels,
				mediaCountForListener: userData.mediaCountForListener,
			});
			posthog.identify(
				userData.user?.publicId,
				{ email: userData.user?.email, name: userData.user?.name }
			);
		});
	}, []);

	return (
		<UserContext.Provider value={{ ...user, setListeners, setIsDebugMode }}>
			{children}
		</UserContext.Provider>
	);
};
