import axios from 'axios';

class API {
	static async getUserFromSession() {
		const user = await axios.get('/api/user/from-session');

		return user.data;
	}

	static async viewAsUser(email: string) {
		const res = await axios.post('/api/admin/view-as', { email });
		const { data } = res;
		if (!data?.id) {
			console.error('User not found');
			return;
		}
		document.cookie = `view-as=${data.publicId}`;
		window.location.href = '/';
	}

	static async clearViewAs() {
		document.cookie = 'view-as=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		window.location.href = '/';
	}

	static async getMyPlan() {
		const res = await axios.get('/api/plans/my-plan');
		return res.data;
	}

	static async submitContactForm(message: string) {
		await axios.post('/api/user/contact-form', { message });
	}

	static async createListener(payload: any) {
		const res = await axios.post('/api/listener', payload);
		return res.data;
	}

	static async getListeners() {
		const res = await axios.get('/api/listener');
		return res.data;
	}

	static async getRelevantMedia(page: number, listeners: number[], type: string, sort: string) {
		const res = await axios.get('/api/media/relevant', { params: { page, listeners, type, sort } });
		return res.data;
	}

	static async getCalibrationMedia(listenerId: number) {
		const res = await axios.get(`/api/media/${listenerId}/calibration`);
		return res.data;
	}

	static async calibrateListenerFromMedia(listenerId: number, mediaId: number, isGood: boolean) {
		await axios.post(`/api/listener/${listenerId}/calibrate-media/${mediaId}`, { isGood });
		return true;
	}

	static async calibrateListener(listenerId: number) {
		await axios.post(`/api/listener/${listenerId}/calibrate`);
		return true;
	}

	static async generatePromptFromUrl(url: string) {
		const res = await axios.post('/api/listener/prompt-from-url', { url });
		return res.data;
	}

	static async getOutputChannels() {
		const res = await axios.get('/api/output-channels/by-team');
		return res.data;
	}

	static async deleteListener(id: number) {
		await axios.delete(`/api/listener/${id}`);
	}

	static async logout() {
		await axios.post('/api/user/logout');
	}

	static async bookmarkMedia(id: number, isBookmarked: boolean) {
		await axios.post(`/api/media/${id}/bookmark`, { isBookmarked });
	}

	static async markMediaIrrelevant(mediaId: number, reason: string) {
		await axios.post(`/api/media/${mediaId}/mark-irrelevant`, { reason });
	}

	static async updateListener(id: number, data: any) {
		await axios.post(`/api/listener/${id}`, data);
	}

	static async markMediaResponded(id: number, isResponded: boolean) {
		await axios.post(`/api/media/${id}/mark-responded`, { isResponded });
	}

	static async postComment(id: number, comment: string) {
		await axios.post(`/api/media/${id}/comment`, { comment });
	}

	static async getAlbertRfps(filters) {
		const res = await axios.get('/api/media/temp-rfps', { params: filters });
		return res.data;
	}
	static async getFulfillMedia(filters) {
		const res = await axios.get('/api/media/temp-fulfill', { params: filters });
		return res.data;
	}
	static async getProducts(filters) {
		const res = await axios.get('/api/media/temp-products', { params: filters });
		return res.data;
	}
}

export default API;
