import React from 'react';
import { PlanMember, User } from '@prisma/client';
import { Avatar, Box, IconButton, Typography } from '@mui/joy';
import { ReactComponent as ThreeDots } from '@/public/icons/threedots.svg';
import styled from '@emotion/styled';


const ItemContainer = styled(Box)`
	display: flex;
	align-items: center;
	padding: 1rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
`;

const HideOnMobile = styled.div`
	@media (max-width: 768px) {
		display: none;
	}
`;

type PlanMemberWithUser = PlanMember & { user: User };


export const UserMemberRow = ({ planMember }: {planMember: PlanMemberWithUser}) => {
	return (
		<>
			<ItemContainer>
				<Box display='flex' flexDirection='row' gap={1} alignItems='center'>
					<HideOnMobile>
						<Avatar size='sm' src={planMember.user.imageUrl} />
					</HideOnMobile>
					<Box>
						<Typography level='title-md'>{planMember.user.name}</Typography>
						<Typography level='body-xs'>{planMember.user.email}</Typography>
					</Box>
				</Box>
			</ItemContainer>
			<ItemContainer style={{ justifyContent: 'center' }}>
			</ItemContainer>
			<ItemContainer>
				<Box display='flex' justifyContent='flex-end'>
					<IconButton variant='soft' size='sm'>
						<ThreeDots width={14} height={14} />
					</IconButton>
				</Box>
			</ItemContainer>
		</>
	);
};

export const UserMemberHeaderRow = () => {
	return (
		<>
			<ItemContainer style={{ paddingBottom: '0.5rem' }}>
				{/* <Box display='flex' justifyContent='center' alignItems='center' width='32px' height='32px' /> */}
				<Typography level='body-xs'>User</Typography>
			</ItemContainer>
			<ItemContainer style={{ paddingBottom: '0.5rem' }}>
				<Typography level='body-xs'>Role</Typography>
			</ItemContainer>
			<ItemContainer style={{ paddingBottom: '0.5rem' }}>
				<Box display='flex' justifyContent='flex-end'>

				</Box>
			</ItemContainer>
		</>
	);
};

export default UserMemberRow;
