// import 'core-js/stable';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import '../public/index.css';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@emotion/react';
import { joyTheme, muiTheme } from '@/src/styles/theme';
import App from './App';
import { UserProvider } from './context/UserContext';
import { PostHogConfig } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
	experimental_extendTheme as materialExtendTheme,
	Experimental_CssVarsProvider as MaterialCssVarsProvider,
	THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import AppConfigurationProvider from './context/AppContext';
import 'reactflow/dist/style.css';

const container = document.getElementById('root');

// posthog.init('phc_kTmN0MRZy5MeHuMuGvJ1mueLAaERFGz3wv6sSOC5aMn', { api_host: 'https://app.posthog.com' });

const options: Partial<PostHogConfig> = {
	api_host: window.location.origin === 'http://localhost:3000' ? 'http://localhost:3000' : 'https://app.posthog.com',
};

if (!container) {
	throw new Error('No root element found');
}


const root = createRoot(container);
const materialTheme = materialExtendTheme(muiTheme);

root.render((
	<AppConfigurationProvider>
		<PostHogProvider apiKey='phc_kTmN0MRZy5MeHuMuGvJ1mueLAaERFGz3wv6sSOC5aMn' options={options}>
			<MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
				<JoyCssVarsProvider theme={joyTheme}>
					<CssBaseline>
						<UserProvider>
							<Router>
								<App />
							</Router>
						</UserProvider>
					</CssBaseline>
				</JoyCssVarsProvider>
			</MaterialCssVarsProvider>
		</PostHogProvider>
	</AppConfigurationProvider>
));

// serviceWorker.unregister();
