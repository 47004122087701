import { Box, Button, Checkbox, Chip, Grid, Option, Select, Typography } from '@mui/joy';
import { Fade, Paper } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import Media, { MediaSkeleton } from '../Media';
import { type Media as MediaType } from '@prisma/client';
import styled from '@emotion/styled';
import API from '@/src/api';
import DashboardHeader from '../DashboardHeader';
import { PageContainer } from '@/src/BaseStyles';
import { UserContext } from '@/src/context/UserContext';
import { trimStringEllipses } from '@/src/util';
import { formatUrlWithoutProtocol } from '@/server/util';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactComponent as BookmarkFilledIcon } from '@/public/icons/bookmark-filled.svg';

const RightContainer = styled(Box)`
	position: sticky;
	top: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	flex-basis: 350px;
	flex-shrink: 0;
	@media (max-width: 768px) {
		display: none;
	}
`;

const Container = styled(PageContainer)`
`;

const FeedContainer = styled(Grid)`
	flex-grow: 1;
`;

const ContentContainer = styled(Box)`
	display: grid;
	grid-template-columns: 350px 1fr;
	gap: 1rem;
	align-items: flex-start;
	@media (max-width: 768px) {
		grid-template-columns: 100%;
	}
`;

const CheckBoxContainer = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-top: .5rem;
`;

enum FeedFilter {
	UnResponded = 'unresponded',
	Saved = 'saved',
	Responded = 'responded',
}

enum SortType {
	Found = 'found',
	Newest = 'newest',
	Relevance = 'relevance',
}

const Feed = () => {
	const [media, setMedia] = React.useState<any[]>([]);
	const [loading, setLoading] = React.useState(true);
	const [searchParams, setSearchParams] = useSearchParams();
	const [page, setPage] = React.useState(searchParams.get('page') ? parseInt(searchParams.get('page'), 10) : 1);
	const [sort, setSort] = React.useState<SortType>(searchParams.get('sort') as SortType || SortType.Relevance);
	const [selectedListeners, setSelectedListeners] = React.useState<number[]>(searchParams.get('listeners')?.split(',')?.map(l => parseInt(l, 10)) || []);
	const { listeners, mediaCountForListener } = useContext(UserContext);
	const [feedFilter, setFeedFilter] = React.useState<FeedFilter>(searchParams.get('type') as FeedFilter || FeedFilter.UnResponded);
	const navigate = useNavigate();

	// const selectedListeners = searchParams.get('listeners')?.split(',');

	useEffect(() => {
		setLoading(true);
		// update search params
		const queryParams = new URLSearchParams(location.search);

		if (selectedListeners.length === 0) {
			queryParams.delete('listeners');
		} else {
			queryParams.set('listeners', selectedListeners.join(','));
		}

		if (feedFilter?.length) {
			queryParams.set('type', feedFilter.toString());
		}

		queryParams.set('page', page.toString());
		queryParams.set('sort', sort);

		navigate({ search: queryParams.toString() });

		API.getRelevantMedia(page, selectedListeners, feedFilter, sort).then((data) => {
			console.log(data);
			setMedia(data);
			setLoading(false);
		});
	}, [selectedListeners, feedFilter, page, sort]);


	return (
		<Container>
			<DashboardHeader />
			<ContentContainer>
				<RightContainer>
					<Paper>
						<Typography level='title-lg'>Listeners</Typography>
						<CheckBoxContainer>
							{listeners?.map((listener) => (
								<Box key={listener.id} display='flex' gap={2} alignItems='center'>
									<Checkbox
										sx={{ flexGrow: 1 }}
										size='sm'
										checked={selectedListeners?.includes(listener.id)}
										onChange={(e) => {
											if (e.target.checked) {
												setSelectedListeners([...selectedListeners, listener.id]);
											} else {
												setSelectedListeners(selectedListeners.filter((id) => id !== listener.id));
											}
										}}
										label={formatUrlWithoutProtocol(listener.sourceUrl) || trimStringEllipses(listener.prompt, 65)}
									/>
									<Typography sx={{ flexShrink: 0 }} level='title-sm' color='neutral'>{mediaCountForListener[listener.id]}</Typography>
								</Box>
							))}
						</CheckBoxContainer>
					</Paper>
				</RightContainer>
				<FeedContainer>
					<Box display='flex' gap={0.5} paddingBottom={1}>
						<Button onClick={() => setFeedFilter(FeedFilter.UnResponded)} color={feedFilter === FeedFilter.UnResponded ? 'primary' : 'neutral'} variant={feedFilter === FeedFilter.UnResponded ? 'solid' : 'outlined'}>To Respond</Button>
						<Button onClick={() => setFeedFilter(FeedFilter.Responded)} color={feedFilter === FeedFilter.Responded ? 'primary' : 'neutral'} variant={feedFilter === FeedFilter.Responded ? 'solid' : 'outlined'}>Responded</Button>
						<Button onClick={() => setFeedFilter(FeedFilter.Saved)} color={feedFilter === FeedFilter.Saved ? 'primary' : 'neutral'} variant={feedFilter === FeedFilter.Saved ? 'solid' : 'outlined'} startDecorator={<BookmarkFilledIcon width={12} height={12} fill='currentColor' />}>Saved</Button>
						<Select sx={{ marginLeft: 'auto', background: 'none' }} variant='outlined' value={sort} onChange={(e, value) => setSort(value)}>
							<Option value={SortType.Newest}>Newest</Option>
							<Option value={SortType.Relevance}>Relevance</Option>
							<Option value={SortType.Found}>Found</Option>
						</Select>
					</Box>
					<Paper style={{ padding: 0 }}>
						<Box display='flex' flexDirection='column'>
							{loading ? (
								<>
									<MediaSkeleton />
									<MediaSkeleton />
									<MediaSkeleton />
									<MediaSkeleton />
									<MediaSkeleton />
									<MediaSkeleton />
									<MediaSkeleton />
								</>
							) : (
								<>
									{media.map((media, index) => (
										<Media key={media.id} media={media} />
									))}
								</>
							)}
						</Box>
					</Paper>
					<Box display='flex' gap={0.5} justifyContent='space-between' paddingTop={1}>
						<Button disabled={page === 1} variant='soft' color='primary' onClick={() => setPage(page - 1)}>Previous</Button>
						<Button disabled={media.length < 25} variant='soft' color='primary' onClick={() => setPage(page + 1)}>Next</Button>
					</Box>
				</FeedContainer>
			</ContentContainer>
		</Container>
	);
};

export default Feed;
