export const formatUrlWithProtocol = (url: string) => {
	if (!url) return null;

	if (!url.startsWith('http')) {
		return `https://${url}`;
	}
	return url;
};

export const formatUrlWithoutProtocol = (url?: string) => {
	if (!url) {
		return '';
	}
	if (url.startsWith('https://')) {
		return url.slice(8);
	}
	if (url.startsWith('http://')) {
		return url.slice(7);
	}
	return url;
};

export const trimStringEllipses = (str: string, length: number) => {
	if (str.length <= length) {
		return str;
	} else {
		return str.slice(0, length) + '...';
	}
};

export const callWithTimeout = async (promise, timeout) => {
	let timer;
	const timeoutPromise = new Promise((_, reject) => {
		timer = setTimeout(() => {
			reject(new Error(`Function timed out after ${timeout} milliseconds`));
		}, timeout);
	});

	return Promise.race([promise, timeoutPromise]).finally(() => clearTimeout(timer));
};

