import styled from '@emotion/styled';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography, colors } from '@mui/joy';
import { Paper } from '@mui/material';
import { ReactComponent as PlusIcon } from '@/public/icons/plus.svg';
import AddListenerModal from '../Dashboard/AddListenerModal';
import { PageContainer } from '@/src/BaseStyles';
import DashboardHeader from '../Dashboard/DashboardHeader';
import OnboardingModal from '../Dashboard/OnboardingModal';
import { UserContext } from '@/src/context/UserContext';
import ReactFlow, { Background, Controls, MiniMap, applyEdgeChanges, applyNodeChanges } from 'reactflow';
import SourceNode from './nodes/SourceNode';
import DataOutputNode from './nodes/DataOutputNode';
import ConfigurationHeader, { ConfigurationTabs } from './ConfigurationHeader';
import ConfigurationDashboard from './ConfigurationDashboard';
import ConfigurationTree from './ConfigurationTree';

const ListenerContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 0fr 0fr 0fr;
	@media (max-width: 768px) {
		grid-template-columns: 1fr 0fr;
	}
	& > * {
		border-bottom: 1px solid ${colors.grey[200]};
	}
`;

const SectionContainer = styled.div`
	flex-grow: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

	@media (max-width: 900px) {
		grid-template-columns: 100%;
	}
`;

const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;
  max-width: 100%;
	width: 100%;
`;

const Root = styled(Box)`
  display: flex;
`;

const TopContainer = styled(Box)`
	display: flex;
	justify-content: space-between;
`;

const BottomContainer = styled(Box)`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	@media (max-width: 768px) {
		grid-template-columns: 100%;
	}
`;


const initialNodes = [
	{ id: '1', type: 'sourceNode', position: { x: 0, y: 0 }, data: { label: 'canadabuys.com' } },
	{ id: '2', type: 'sourceNode', position: { x: 200, y: 0 }, data: { label: 'merx.com' } },
	{ id: '3', type: 'dataOutputNode', position: { x: 100, y: 150 }, data: { label: 'rfps' } },
];
const initialEdges = [
	{ id: 'e1-3', source: '1', target: '3' },
	{ id: 'e2-3', source: '2', target: '3' },
];

const ConfigurationV2 = () => {
	const [tab, setTab] = React.useState<ConfigurationTabs>(ConfigurationTabs.Filtering);

	const nodeTypes = useMemo(() => ({ sourceNode: SourceNode, dataOutputNode: DataOutputNode }), []);

	const [nodes, setNodes] = useState(initialNodes);
	const [edges, setEdges] = useState(initialEdges);

	const onNodesChange = useCallback(
		// @ts-ignore
		(changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
		[],
	);
	const onEdgesChange = useCallback(
		(changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
		[],
	);

	return (
		<Root>
			<ConfigurationTree />
			<Container>
				<Box sx={{ paddingX: '2rem', paddingTop: '1rem' }} zIndex={1000}>
					<DashboardHeader noMarginBottom />
				</Box>
			</Container>
		</Root>
	);
};

export default ConfigurationV2;
