import { Box, Button, Checkbox, Chip, Grid, Input, Option, Select, Tab, TabList, TabPanel, Tabs, Typography } from '@mui/joy';
import { Fade, Paper } from '@mui/material';
import React, { useContext, useEffect, useMemo } from 'react';
// import Media, { MediaSkeleton } from '../Media';
import styled from '@emotion/styled';
import API from '@/src/api';
// import DashboardHeader from './DashboardHeader';
import { PageContainer } from '@/src/BaseStyles';
import { UserContext } from '@/src/context/UserContext';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import RFPMedia from './RFPMedia';
import { useDebounce } from 'use-debounce';
import moment from 'moment';
import DashboardHeader from '../Dashboard/DashboardHeader';
import TextEditor from '@/src/components/TextEditor';


const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`;

const ContentContainer = styled(Box)`
  flex-grow: 1;
  max-height: 100%;
`;

const StyledTabPanel = styled(TabPanel)`
  background: white;
`;

const content = `
<h1>
  Executive Summary
</h1>
<h3>
  Company Overview
</h3>
<p>
  Acme Inc. is a company that provides services in the field of software development. We have been in business for over 10 years and have a proven track record of delivering high-quality products to our clients. Our team of experienced developers is dedicated to providing the best possible solutions to our customers.
</p>
<p>
  Our mission is to help our clients achieve their business goals by providing them with innovative and cost-effective software solutions. We are committed to delivering projects on time and within budget, and we pride ourselves on our ability to adapt to the changing needs of our clients.
</p>
<p>
  At Acme Inc., we believe in building long-term relationships with our clients based on trust, integrity, and mutual respect. We are passionate about what we do, and we are always looking for new ways to improve our services and exceed our clients' expectations.
</p>
<br/>
<br/>
<h3>
  Understanding of Requirements
</h3>
<br />
<br />
<h1>
  Past Performance
</h1>
`;

const Persuit = () => {
	const params = useParams();
	const { id } = params;
	console.log(params);

	useEffect(() => {
		// const persuit = API.getPersuit(id);
	}, [id]);


	return (
		<Container>
			<DashboardHeader />
			<Tabs
				sx={{ flexGrow: 1 }}
			>
				<TabList>
					<Tab variant='outlined'>
            Details
					</Tab>
					<Tab variant='outlined'>
            Compliance
					</Tab>
					<Tab variant='outlined'>
            Documents
					</Tab>
					<Tab variant='outlined'>
            Draft
					</Tab>
				</TabList>
				<StyledTabPanel value={0}>
					<ContentContainer>
          Details
					</ContentContainer>
				</StyledTabPanel>
				<StyledTabPanel value={1}>
					<ContentContainer>
          Compliance
					</ContentContainer>
				</StyledTabPanel>
				<StyledTabPanel value={2}>
					<ContentContainer>
          Documents
					</ContentContainer>
				</StyledTabPanel>
				<StyledTabPanel value={3}>
					<ContentContainer>
						<TextEditor content={content} />
					</ContentContainer>
				</StyledTabPanel>
			</Tabs>
		</Container>
	);
};

export default Persuit;
