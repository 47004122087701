import React, { useContext } from 'react';
import styled from '@emotion/styled';
import Alex from '../svgs/Alex';
import Icon from '@/src/../public/icon.png';
import { UserContext } from '../context/UserContext';
import { Button } from '@mui/joy';


const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  padding: .5rem;
  gap: 1rem;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
	background-color: rgba(240, 240, 240, 0.8);
	z-index: 10000;
`;

const Logo = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 42px;
  border: 2px solid ${props => props.theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  background-color: ${props => props.theme.colors.secondary};
`;

const Header: React.FC = () => {
	const { user, loaded } = useContext(UserContext);
	const isLoggedIn = loaded && user?.email;

	return (
		<HeaderWrapper>
			<a href='/'>
				<img src={Icon} alt='icon' width='42px' height='42px' />
			</a>
			{isLoggedIn ? (
				<a href='/'>
					<Button>Dashboard</Button>
				</a>
			) : (
				<a href='/login'>
					<Button>Sign up</Button>
				</a>
			)}
			{/* <a href="https://ta124tyg42e.typeform.com/to/SmevfpZR" target="_blank" rel="noreferrer">
				<ButtonSecondary>Join Waitlist</ButtonSecondary>
			</a> */}

		</HeaderWrapper>
	);
};

export default Header;
