import ButtonPrimary, { ButtonSecondary } from '@/src/components/Button';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { ReactComponent as PlusIcon } from '@/public/icons/plus.svg';
import { ReactComponent as MinusIcon } from '@/public/icons/minus.svg';
import { Paper, Typography } from '@mui/material';

interface FAQ {
  question: string;
  answer: React.ReactNode;
}

const FaqsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 100%;
  width: 600px;
`;

const FaqContainer = styled(Paper)`
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  padding: 1rem;
`;

const FaqTextContainer = styled.div`
  flex-grow: 1;
`;

const ExpandButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 8px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.black};
  outline: none;
  cursor: pointer;
  background: none;
`;


const FAQItem = ({ faq }: { faq: FAQ }) => {
	const [open, setOpen] = useState(false);

	return (
		<FaqContainer elevation={1}>
			<FaqTextContainer>
				<Typography variant='h5' sx={{ marginBottom: '.5rem' }}>{faq.question}</Typography>
				{open && <Typography color='grey.800'>{faq.answer}</Typography>}
			</FaqTextContainer>
			<ExpandButton onClick={() => setOpen(!open)}>
				{open ? <MinusIcon width={16} height={16} /> : <PlusIcon width={16} height={16} />}
			</ExpandButton>
		</FaqContainer>
	);
};


const PricingFAQs = () => {

	const faqs: FAQ[] = [
		{
			question: 'What is a listener?',
			answer: 'A listener is a descriptive prompt (and response) related to the type of problem or discussion you are looking to monitor. The relevance of the posts found will be based on how well it matches the listener.'
		},

		{
			question: 'What is post?',
			answer: 'A post is a single relevant Reddit post the listener finds based on the prompt',
		},
		{
			question: 'What is the best plan for me?',
			answer: <>
			Choosing the best plan depends on your specific goals with Reddit. We recommend the starter plan if you are experimenting with Reddit as a growth channel and want to monitor new discussions.<br/><br/>
			If you are experimenting with Reddit as a growth channel and want to monitor new discussions the Pro plan is Best for businesses focused on detailed understanding of their target market on Reddit and a focus on historical posts.<br/><br/>
			Lastly if you were interested in custom feedback integrations, please reach out to us directly at info@feedflow.ai.
			</>

		},
		{
			question: 'How do you process payments?',
			answer: 'We use Stripe to process payments. We do not store any credit card information on our servers.',
		},
		{
			question: 'What\'s the benefit of being on Reddit?',
			answer: 'Reddit is one of the best organic growth channels at the moment. The hardest part of finding leads however is the time it takes to find the most relevant discussions. We save you that time by finding only the most relevant posts for you.',
		},


		{
			question: 'Is there risk to posting about my business on Reddit?',
			answer: ': When responding to relevant discussions and posts related ot your business it\'s important to understand the rules of the subreddit you\'re commenting in.'+
			'In general, if you are truly providing value and answering a question with your service there shouldn\'t be too much to worry about. If you\'re unsure, each subreddit will have guidelines to read them over. ',
		}
	];


	return (
		<FaqsContainer>
			<Typography sx={{ marginBottom: '.5rem' }} variant='h2' textAlign='center'>Frequently Asked Questions</Typography>
			{faqs.map((faq, index) => (
				<FAQItem key={index} faq={faq} />
			))}
		</FaqsContainer>
	);
};

export default PricingFAQs;
