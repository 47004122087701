import { Box, Button, CircularProgress, DialogContent, Divider, Grid, Modal, ModalClose, ModalDialog, Textarea, Typography, colors } from '@mui/joy';
import React, { useContext, useEffect, useState } from 'react';
// import { ObjectiveType } from '@prisma/client';
import styled from '@emotion/styled';
import { ListenerTypeWithSources, OBJECTIVE_DETAILS } from './ListenerRow';
import { ReactComponent as CheckIcon } from '@/public/icons/check.svg';
import { ReactComponent as RedditIcon } from '@/public/icons/reddit.svg';
import { ReactComponent as XTwitterIcon } from '@/public/icons/xtwitter.svg';
import { ReactComponent as QuoraIcon } from '@/public/icons/quora.svg';
import { ReactComponent as LinkedInIcon } from '@/public/icons/linkedin.svg';
import API from '@/src/api';
import { Paper } from '@mui/material';
import { UserContext } from '@/src/context/UserContext';
import { Media } from '@prisma/client';
import { clear } from 'console';
import ListenerMediaCalibrator from './ListenerMediaCalibrator';
import { useNavigate } from 'react-router-dom';

interface AddListenerModalProps {
	open: boolean;
	onClose: () => void;
	listener?: ListenerTypeWithSources;
}

const ObjectivesContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	@media (max-width: 768px) {
		grid-template-columns: 100%;
	}
	grid-gap: 1rem;
`;

const SelectContainer = styled.div<{ selected: boolean, disabled?: boolean }>`
	position: relative;
	display: flex;
	flex-direction: column;
	border: 1px solid ${colors.grey[300]};
	box-shadow: ${(props) => (props.selected ? `inset 0px 0px 0px 2px ${colors.blue[500]}` : 'none')};
	border-radius: 8px;
	padding: 1rem;
	align-items: center;
	cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
	aspect-ratio: 1;
	justify-content: center;
	filter: ${(props) => (props.disabled ? 'grayscale(1)' : 'none')};
	opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const SelectCircle = styled.div<{ selected: boolean, isMulti?: boolean }>`
	position: absolute;
	top: .5rem;
	right: .5rem;
	width: 20px;
	height: 20px;
	border-radius: ${(props) => (props.isMulti ? '5px' : '22px')};
	border: 2px solid ${(props) => (props.selected ? colors.blue[500] : colors.grey[300])};
	background-color: ${(props) => (props.selected ? colors.blue[500] : 'transparent')};
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StepDot = styled.div<{ completed: boolean }>`
	width: 12px;
	height: 12px;
	border-radius: 12px;
	background-color: ${(props) => (props.completed ? 'black' : colors.grey[300])};
`;

export const ExampleBox = styled(Paper)`
	background-color: ${colors.grey[100]};
`;

const StyledDialogContent = styled(DialogContent)`
	width: 500px;
	max-width: 100%;
	overflow: hidden;
	@media (max-width: 768px) {
		overflow: auto;
		width: 100%;
	}
`;

// TODO: this will need to be on backend so reference it there somehow
export const ListenerSources = [
	{
		name: 'Reddit',
		icon: RedditIcon,
	},
	{
		name: 'Quora',
		icon: QuoraIcon,
		disabled: false,
	},
	{
		name: 'X',
		icon: XTwitterIcon,
		disabled: true,
	},
	{
		name: 'LinkedIn',
		icon: LinkedInIcon,
		disabled: true,
	}
];

// TODO: move this to base components somewhere
const ContainerSelect = (
	{ selected, onSelect, isMulti = false, disabled = false, children, style }: { selected: boolean, onSelect: () => void, isMulti?: boolean, disabled?: boolean; children: React.ReactNode, style?: React.CSSProperties }
) => {
	return (
		<SelectContainer onClick={disabled ? null : onSelect} disabled={disabled} selected={selected} style={style}>
			<SelectCircle isMulti={isMulti} selected={selected}>
				{selected && <CheckIcon fill='white' width={14} height={14} />}
			</SelectCircle>
			{children}
		</SelectContainer>
	);
};


const ObjectiveSelect = ({ objective, selected, onSelect }: { objective: string, selected: boolean, onSelect: () => void }) => {
	const details = OBJECTIVE_DETAILS[objective];
	return (
		<ContainerSelect selected={selected} onSelect={onSelect}>
			<details.icon fill='black' width={48} height={48} style={{ marginBottom: '1rem' }} />
			<Typography level='title-lg' textAlign='center'>{details.title}</Typography>
			<Typography level='body-sm' textAlign='center'>{details.description}</Typography>
		</ContainerSelect>
	);
};

const AddListenerModal = (props: AddListenerModalProps) => {
	const [step, setStep] = React.useState(0);
	const [selectedObjective, setSelectedObjective] = React.useState<string | null>(null);
	const [selectedSources, setSelectedSources] = React.useState<string[]>(ListenerSources.filter(s => !s.disabled).map((source) => source.name));
	const [prompt, setPrompt] = React.useState<string>('');
	const [loading, setLoading] = React.useState(false);
	const { setListeners, listeners } = useContext(UserContext);
	const [listener, setListener] = useState(props.listener);
	const [calibrationMedia, setCalibrationMedia] = useState<Media[]>([]);
	const [isPolling, setIsPolling] = useState(false);
	const [enoughComplete, setEnoughComplete] = useState(false);
	const navigate = useNavigate();


	useEffect(() => {
		if (props.listener) {
			setSelectedObjective(props.listener.objective);
			setPrompt(props.listener.prompt);
			setSelectedSources(props.listener.sources.map((source) => source.type));
			setListener(props.listener);
			setStep(3);
		}
	}, [props.listener]);

	useEffect(() => {
		if (listener && !calibrationMedia?.length && !isPolling) {
			const intervalid = setInterval(async () => {
				const media = await pollMedia();
				if (media?.length > 0) {
					clearInterval(intervalid);
					setTimeout(() => {
						pollMedia();
					}, 5000);
				}
			}, 3000);
		}
	}, [listener]);

	const pollMedia = async () => {
		console.log('polling...');
		const media = await API.getCalibrationMedia(listener.id);
		console.log(media);
		if (media) {
			setCalibrationMedia(media);
			setLoading(false);
		}
		return media;
	};

	const onStepSubmit = async () => {
		if (step === 0 && selectedObjective) {
			setStep(1);
		} else if (step === 1) {
			setStep(2);
		} else if (step === 2) {
			setLoading(true);
			const newListener = await API.createListener({
				objective: selectedObjective,
				prompt,
				sources: selectedSources,
			});
			setListener(newListener);
			setListeners([...listeners, newListener]);
			setLoading(false);
			setStep(3);
		} else if (step === 3) {
			setLoading(true);
			await API.calibrateListener(listener.id);
			setLoading(false);
			window.location.href = `/?listeners=${listener.id}&type=unresponded&poll=true`;
			props.onClose();
		}
	};

	const onSourceSelect = (source: string) => {
		// remove if already in, add if not
		if (selectedSources.includes(source)) {
			setSelectedSources(selectedSources.filter((s) => s !== source));
		} else {
			setSelectedSources([...selectedSources, source]);
		}
	};

	return (
		<Modal open={props.open} onClose={props.onClose}>
			<ModalDialog>
				<ModalClose onClick={props.onClose} />
				{step < 3 && (
					<Box display='flex' flexDirection='column' >
						<Typography level='h3'>Add Listener</Typography>
						<Typography level='body-sm'>Listen for anything</Typography>
					</Box>
				)}
				{step === 3 && (
					<>
						{calibrationMedia?.length > 0 ? (
							<Box display='flex' flexDirection='column' >
								<Typography level='h3'>Give me some feedback</Typography>
								<Typography level='body-sm'>Let me know what you think so I know what to look for</Typography>
							</Box>
						) : (
							<>
								<Typography level='h3'>Calibrating listener</Typography>
								<Typography level='body-sm'>I&apos;m sourcing the web to find examples. This may take a minute...</Typography>
							</>
						)}
					</>
				)}

				<Divider sx={{ marginBottom: '0.5rem' }} />
				<StyledDialogContent>
					{step === 0 && (
						<ObjectivesContainer>
							<ObjectiveSelect
								objective={'Monitor'}
								selected={selectedObjective === 'Monitor'}
								onSelect={() => setSelectedObjective('Monitor')}
							/>
							<ObjectiveSelect
								objective={'DeepSearch'}
								selected={selectedObjective === 'DeepSearch'}
								onSelect={() => setSelectedObjective('DeepSearch')}
							/>
						</ObjectivesContainer>
					)}
					{step === 1 && (
						<Box>
							<ExampleBox sx={{ marginBottom: '1rem' }}>
								<Typography level='title-lg'>Describe a discussion topic to listen to</Typography>
								<Typography level='body-sm'>For example, people asking how or where to play virtual team building games</Typography>
							</ExampleBox>
							<Textarea
								required
								minRows={3}
								placeholder='People looking for...'
								value={prompt}
								onChange={(e) => setPrompt(e.target.value)}
							/>
						</Box>
					)}
					{step === 2 && (
						<Grid container spacing={1} maxWidth='100%'>
							{ListenerSources.map((source) => (
								<Grid key={source.name} sx={{ aspectRatio: 1 }} xs={8} sm={4}>
									<ContainerSelect disabled={source.disabled} style={{ width: '100%', height: '100%' }} isMulti key={source.name} selected={selectedSources.includes(source.name)} onSelect={() => onSourceSelect(source.name)}>
										<source.icon width={32} height={32} style={{ marginBottom: '1rem', flexShrink: 0 }} />
										<Typography textAlign='center' level='title-lg'>{source.name}</Typography>
									</ContainerSelect>
								</Grid>
							))}
						</Grid>
					)}
					{step === 3 && (
						<Box>
							{calibrationMedia?.length > 0 ? (
								<>
									<ListenerMediaCalibrator media={calibrationMedia} listener={listener} onEnoughComplete={() => setEnoughComplete(true)} />
								</>
							) : (
								<Box display='flex' justifyContent='center' alignItems='center' minHeight='250px'>
									{/* <CircularProgress sx={{ marginTop: '1rem' }} /> */}
									<div className='cool-loader' />
								</Box>
							)}
						</Box>
					)}
				</StyledDialogContent>
				<Divider sx={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
				<Box display='flex' justifyContent='space-between' alignItems='center'>
					<Box display='flex' gap={2}>
						<StepDot completed={step >= 0} />
						<StepDot completed={step >= 1} />
						<StepDot completed={step >= 2} />
						<StepDot completed={step >= 3} />
					</Box>
					<Box display='flex' gap={1}>
						{step > 0 && step < 3  && (
							<Button variant='outlined' color='neutral' onClick={() => setStep(step - 1)}>
								Back
							</Button>
						)}
						<Button
							loading={loading}
							disabled={loading || (step === 0 && !selectedObjective) || (step === 1 && (!prompt || prompt.length < 10)) || (step === 2 && selectedSources.length === 0) || (step === 3 && !enoughComplete)}
							onClick={onStepSubmit}
							variant='solid'
							color='primary'
						>
							{step === 3 ? 'Finish' : 'Next'}
						</Button>
					</Box>

				</Box>
			</ModalDialog>
		</Modal>
	);
};

export default AddListenerModal;
