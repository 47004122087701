import React, { useEffect } from 'react';
import { ReactComponent as FeedFlowIcon } from '@/public/icon.svg';
import Feed from '../pages/Dashboard/sections/Feed';
import Configuration from '../pages/Dashboard/Configuration';
import Team from '../pages/Dashboard/sections/Team';
import RFPFeed from '../pages/RFPDashboard';
import FulfillDashboard from '../pages/FulfillDashboard';

import icon from '@/public/company_icons/icon.png';
import fulfillIcon from '@/public/company_icons/fulfill.png';
import rfpCanadIcon from '@/public/company_icons/rfpcanada.png';
import ConfigurationV2 from '../pages/ConfigurationV2/ConfigurationV2';
import ShinpadsDashboard from '../pages/ShinpadsDashboard';
import Persuit from '../pages/RFPDashboard/Persuit';

export const AppConfigurationContext = React.createContext<AppConfiguration>(null);


export interface AppConfiguration {
	subdomain: string;
  title: string;
  icon: string;
	routes: {};
	isConfigurable?: boolean;
}


function getAppConfiguration(subdomain: string): AppConfiguration {
	const defaultRoutes = {
		'/setup': <Configuration />,
		'/': <Feed />,
		'/team': <Team />,
	};

	if (subdomain === 'rfpcanada') {
		return {
			subdomain,
			title: 'RFP Canada',
			icon: icon,
			isConfigurable: true,
			routes: {
				'/': <RFPFeed />,
				'/persuit/:id': <Persuit />,
			},
		};
	} else if (subdomain === 'fulfill') {
		return {
			subdomain,
			title: 'Fulfill',
			icon: fulfillIcon,
			routes: {
				'/': <FulfillDashboard />,
			}
		};
	} else if (subdomain === 'shinpads') {
		return {
			subdomain,
			title: 'shinpads',
			icon: icon,
			isConfigurable: true,
			routes: {
				'/': <ShinpadsDashboard />,
			},
		};
	}
	return {
		subdomain: 'app',
		title: 'FeedFlow',
		icon: icon,
		routes: defaultRoutes,
	};
}

const AppConfigurationProvider = ({ children }: { children: React.ReactNode }) => {
	const domain = window.location.hostname;
	const subdomain = domain.split('.')[0];
	const searchParams = new URLSearchParams(window.location.search);
	const domainOverride = searchParams.get('domain') || localStorage.getItem('domainOverride');
	const configuration = getAppConfiguration(domainOverride || subdomain);

	return (
		<AppConfigurationContext.Provider value={configuration}>
			{children}
		</AppConfigurationContext.Provider>
	);
};

export default AppConfigurationProvider;
