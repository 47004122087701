import API from '@/src/api';
import styled from '@emotion/styled';
import { Box, Button, Typography, colors } from '@mui/joy';
import { Paper } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import UserMemberRow, { UserMemberHeaderRow } from '../UserMemberRow';
import { PageContainer } from '@/src/BaseStyles';
import DashboardHeader from '../DashboardHeader';
import { UserContext } from '@/src/context/UserContext';

export const SectionContainer = styled.div`
	flex-grow: 1;
	margin-top: 2rem;
`;

const TopContainer = styled(Box)`
	display: flex;
	justify-content: space-between;
`;

const MembersContainer = styled.div`
	display: grid;
	grid-template-columns: 0fr 1fr 0fr;
	& > * {
		border-bottom: 1px solid ${colors.grey[200]};
	}
`;

const Team = () => {
	const { plan, loaded } = useContext(UserContext);

	return (
		<PageContainer>
			<DashboardHeader />
			<Paper>
				<TopContainer>
					<Typography level='h3'>Team</Typography>
					{/* <Button variant='plain' color='primary'>Invite</Button> */}
				</TopContainer>
				<MembersContainer>
					<UserMemberHeaderRow />
					{plan?.members?.map((member) => (
						<UserMemberRow key={member.id} planMember={member} />
					))}
				</MembersContainer>
			</Paper>
		</PageContainer>
	);
};

export default Team;
