// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tiptap {
  border: 0;
  outline: 0;
  min-height: 100vh;
  padding: 1rem 3rem;
  padding-top: 4rem;

  h1, h2, h3, h4, h5, h6 {
    margin: .5rem 0;
  }
  p {
    margin: 0.25rem 0;
  }
}

`, "",{"version":3,"sources":["webpack://./src/components/TextEditor/Tiptap.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;;EAEjB;IACE,eAAe;EACjB;EACA;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".tiptap {\n  border: 0;\n  outline: 0;\n  min-height: 100vh;\n  padding: 1rem 3rem;\n  padding-top: 4rem;\n\n  h1, h2, h3, h4, h5, h6 {\n    margin: .5rem 0;\n  }\n  p {\n    margin: 0.25rem 0;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
