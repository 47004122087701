import { Box, Button, DialogContent, Divider, Grid, Input, Modal, ModalClose, ModalDialog, Textarea, Typography, colors } from '@mui/joy';
import React, { useContext } from 'react';
import { ObjectiveType } from '@prisma/client';
import styled from '@emotion/styled';
import { OBJECTIVE_DETAILS } from './ListenerRow';
import { ReactComponent as CheckIcon } from '@/public/icons/check.svg';
import { ReactComponent as RedditIcon } from '@/public/icons/reddit.svg';
import { ReactComponent as XTwitterIcon } from '@/public/icons/xtwitter.svg';
import { ReactComponent as QuoraIcon } from '@/public/icons/quora.svg';
import API from '@/src/api';
import { Paper } from '@mui/material';
import { UserContext } from '@/src/context/UserContext';

interface MarkIrrelevantModalProps {
	open: boolean;
	onClose: () => void;
  onMarkIrrelevant: (reason: string) => void;
}


export const ExampleBox = styled(Paper)`
	background-color: ${colors.grey[100]};

`;


const MarkIrrelevantModal = (props: MarkIrrelevantModalProps) => {
	const [loading, setLoading] = React.useState(false);
	const [reason, setReason] = React.useState('');

	const onSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		props.onMarkIrrelevant(reason);
		props.onClose();
	};

	return (
		<Modal open={props.open} onClose={props.onClose}>
			<form onSubmit={onSubmit}>
				<ModalDialog>
					<ModalClose onClick={props.onClose} />
					<Box display='flex' flexDirection='column' >
						<Typography level='h3'>Exclude posts like this</Typography>
						<Typography level='body-sm'>Why should I exclude this in the future?</Typography>
					</Box>
					<Divider sx={{ marginBottom: '0.5rem' }} />
					<DialogContent>
						<Input
							placeholder='Mentions topic X, Y, or Z'
							value={reason}
							onChange={(e) => setReason(e.target.value)}
						/>
					</DialogContent>
					<Divider sx={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
					<Box display='flex' justifyContent='flex-end' alignItems='center'>
						<Button
							variant='solid'
							color='danger'
							type='submit'
						>
							Exclude
						</Button>
					</Box>
				</ModalDialog>
			</form>

		</Modal>
	);
};

export default MarkIrrelevantModal;
