import API from '@/src/api';
import { Button, Input, Modal, ModalClose, ModalDialog } from '@mui/joy';
import { Typography } from '@mui/material';
import axios from 'axios';
import React from 'react';

const Admin = () => {
	const [viewAsModalOpen, setViewAsModalOpen] = React.useState(false);
	const [viewAsEmail, setViewAsEmail] = React.useState('');

	const onSyncRobListener = async () => {
		await axios.post('/api/admin/sync-listener/9');
	};

	const onViewAs = async (e: React.FormEvent) => {
		e.preventDefault();
		await API.viewAsUser(viewAsEmail);
	};


	return (
		<>
			<div>
				<Button color='danger' onClick={onSyncRobListener}>Sync Rob&apos;s Listener</Button>
				<Button color='danger' onClick={() =>  setViewAsModalOpen(true)}>View as</Button>
			</div>
			<Modal open={viewAsModalOpen} onClose={() => setViewAsModalOpen(false)}>
				<ModalDialog>
					<ModalClose />
					<Typography>Admin view-as</Typography>
					<form onSubmit={onViewAs}>
						<Input placeholder='Email' value={viewAsEmail} onChange={(e) => setViewAsEmail(e.target.value)} />
						<Button type='submit'>Submit</Button>
					</form>
				</ModalDialog>
			</Modal>
		</>
	);
};

export default Admin;
